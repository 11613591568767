.modal-newsletter {
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 2;
  width: calc(100% - 20px);
  height: 100%;
  overflow-y: auto;
  top: 40px;
  max-height: 470px;
  padding: 16px 24px;

  @media (min-width: 768px) {
    max-width: 600px;
    width: 100%;
  }

  @media (min-width: 1024px) {
    max-width: 990px;
    top: calc(50% - 275px);
    overflow-y: hidden;
  }

  @media (min-width: 1200px) {
    max-width: 1100px;
  }

  @media (min-width: 1440px) {
    max-width: 1300px;
  }

  &-close {
    position: absolute;
    top: 15px;
    right: 15px;
    overflow: visible;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    -webkit-appearance: none;
    outline: 0;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 30px;
    height: 30px;
    line-height: 1;
    float: right;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: $light-grey-text-color;
      width: 30px;
      height: 30px;
    }
  }
}
