//$popupWidthXs: 320px;
//$popupWidthMob: 375px;
//$popupHeightMob1: 638px;
//$popupWidthTab: 768px;
//$popupHeightTab1: 414px;
//$popupWidthLap: 1024px;
//$popupWidthDesk: 1200px;
//$popupHeightDesk1: 414px;
//$popupWidthDeskLg: 1400px;
//$popupHeightDeskLg1: 483px;

//@mixin centerPopup($width, $height) {
//  max-width: $width;
//  max-height: $height;
//  left: calc(50% - #{$width / 2});
//  top: calc(50% - #{$height / 2});
//}

.exit-popup {
  background: linear-gradient(
    90deg,
    #0b1550 30.91%,
    #0b1550 30.92%,
    #046195 65.34%,
    #056194 65.34%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 2;
  width: 100%;
  color: #fff;
  height: 100%;
  max-height: 850px;
  overflow-y: auto;
  top: 0;

  @media (min-width: 768px) {
    max-width: 600px;
  }

  @media (min-width: 1024px) {
    max-width: 990px;
    height: 550px;
    top: calc(50% - 275px);
    overflow-y: hidden;
  }

  @media (min-width: 1200px) {
    max-width: 1100px;
  }

  @media (min-width: 1440px) {
    max-width: 1300px;
  }
  //@media (min-width: 375px) and (min-height: 638px) {
  //  @include centerPopup($popupWidthMob, $popupHeightMob1);
  //}
  //
  //@media (min-width: 768px) {
  //  @include centerPopup($popupWidthTab, $popupHeightTab1);
  //}
  //
  //@media (min-width: 1024px) {
  //  @include centerPopup($popupWidthLap, $popupHeightTab1);
  //}
  //
  //@media (min-width: 1200px) {
  //  @include centerPopup($popupWidthDesk, $popupHeightDesk1);
  //}
  //
  //@media (min-width: 1440px) {
  //  @include centerPopup($popupWidthDeskLg, $popupHeightDeskLg1);
  //}

  .grid {
    display: grid;
    grid-gap: var(--basic-section-gap);

    &-2 {
      grid-template-columns: repeat(1, 1fr);

      @media (min-width: 1024px) {
        grid-template-columns: repeat(2, minmax(50%, 1fr));
      }
    }

    &.gap-2 {
      grid-gap: 32px;
    }
  }

  .partnership {
    font-size: 14px;
    color: #b4b4b4;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    @media (min-width: 1024px) {
      justify-content: flex-start;
    }
  }

  .wrap {
    width: 100%;
    padding: 60px 16px 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      padding: 60px var(--page-gutter);
      margin-right: auto;
      margin-left: auto;
      max-width: 100%;
    }

    @media (min-width: 1024px) {
      justify-content: center;
      overflow-y: hidden;
    }

    @media (max-width: 767px) {
      &::after {
        content: '';
        background: rgba(44, 123, 253, 0.2);
        display: block;
        position: absolute;
        top: -200px;
        left: -160px;
        border-radius: 50%;
        filter: blur(100px);
        z-index: -1;
        width: 400px;
        height: 400px;
        overflow: hidden;
        // hack to make this work in safari
        // it would appear this does nothing but it stops these circles being squares in safari
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .basic-heading {
    font-size: 25px;
    font-weight: 700;
    text-align: center;

    @media (min-width: 1024px) {
      text-align: left;
    }
  }

  .htt-btn {
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 285px;
    }

    &::after {
      -webkit-mask-size: 30px;
      mask-size: 30px;
      mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 25'%3e%3cg clip-path='url(%23n-15-a)'%3e%3cpath d='m22.5 12.647-4-4v3h-15v2h15v3l4-4Z' fill='currentColor'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='n-15-a'%3e%3cpath fill='currentColor' transform='translate(.5 .647)' d='M0 0h24v24H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
    }
  }

  .exit-image {
    max-width: 500px;
    width: 100%;
    bottom: calc(0px - var(--page-gutter));
    position: relative;
    display: none;

    @media (min-height: 835px) and (max-width: 1023px) {
      display: block;
    }

    @media (min-width: 1024px) {
      display: block;
    }
  }

  &__numbered {
    margin-bottom: 24px;

    &__item {
      background: #365282;
      font-weight: 400;
      font-size: 15px;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 90px;
      gap: 8px;
      margin-bottom: 24px;
      position: relative;

      &:last-child {
        margin-bottom: 0;

        &::after {
          content: none;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 4px;
        position: absolute;
        left: 25px;
        height: 38px;
        bottom: -32px;
        background: #365282;
        z-index: -1;
      }

      &__number {
        background: transparent;
        font-size: 16px;
        font-weight: 700;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 36px;
      }

      &.first {
        background: #4581b2;

        .exit-popup__numbered__item__number {
          background: #448ff7;
        }

        &::after {
          background: linear-gradient(180deg, #448ff7 0%, #365282 100%);
        }
      }
    }
  }

  .trustpilot-reviews {
    justify-content: center;

    @media (min-width: 1024px) {
      justify-content: flex-start;
    }
  }

  &-close {
    position: absolute;
    top: 15px;
    right: 15px;
    overflow: visible;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    -webkit-appearance: none;
    outline: 0;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 30px;
    height: 30px;
    line-height: 1;
    float: right;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: #fff;
      width: 30px;
      height: 30px;
      filter: drop-shadow(0 0 4px #000);
    }
  }
}
