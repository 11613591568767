%input {
  font-size: 16px;
  font-weight: 400;
  border: 1px solid $light-grey-border-color;
  border-radius: 0;
  outline: none;
  padding: 16px;
  width: 100%;
  background: $bg-color;
  color: $dark-text-color;

  &:focus {
    box-shadow: 0 0 15px -5px rgb($color-htt-blue, 0.1);
    border: 1px solid $dark-text-color;
  }

  &:invalid:not(:focus):not(:placeholder-shown) {
    border-color: $error-color;
  }

  &::placeholder {
    color: #9b9b9b;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
}

.form {
  .htt-btn.submit {
    margin-top: 8px;
  }
}

.form-row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: space-between;
  grid-gap: 5px 16px;
  margin-bottom: 6px;
  position: relative;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @for $i from 1 through 10 {
    & > *:nth-child(#{$i}) {
      order: $i;

      @media (min-width: 1024px) {
        order: $i;
      }
    }
  }

  .form-input {
    &:nth-child(1) {
      order: 1;

      @media (min-width: 1024px) {
        order: 1;
      }
    }

    &:nth-child(2) {
      order: 3;

      @media (min-width: 1024px) {
        order: 2;
      }
    }
  }

  .form-error {
    &:nth-child(3) {
      order: 2;

      @media (min-width: 1024px) {
        order: 3;
      }
    }

    &:nth-child(4) {
      order: 4;

      @media (min-width: 1024px) {
        order: 4;
      }
    }
  }
}

.form-group {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  margin-bottom: 8px;
  position: relative;

  &.has-select {
    position: relative;

    &::after {
      @include sprite-mask('chevron-down');
      content: '';
      position: absolute;
      top: calc(24px + 5px + 25px);
      right: 5%;
      width: 12px;
      height: 8px;
      background: #fff;
      transform: translateY(-50%);
      z-index: -1;
    }
  }
}

.form-pw-container {
  position: relative;

  .view-password-toggle {
    top: 50%;
    transform: translateY(-50%);
  }
}

.form-group {
  .form-input {
    @extend %input;
  }
}

.form-radio,
.form-checkbox {
  appearance: none;
  -webkit-appearance: none;
  display: none;
}

.form-radio__label__inner,
.form-checkbox__label__inner {
  background: #ffffff;
  border: 1px solid $color-htt-blue;
  padding: 8px 20px;
  color: $color-htt-blue;
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.form-radio__label .form-radio:checked + .form-radio__label__inner,
.form-checkbox__label .form-checkbox:checked + .form-checkbox__label__inner {
  border: 1px solid $color-htt-blue;
  background: $color-htt-blue;
  color: #fff;
}

.form-label {
  font-weight: 500;
  overflow: hidden;
  pointer-events: none;
  font-size: 13px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  max-width: 100%;
  line-height: 160%;
}

.popup,
.hero-reg-step {
  .form-label {
    top: -8px;
    left: 8px;
    color: $dark-text-color;
    position: absolute;
    padding-left: 10px;
    background: #fff;
    padding: 0 8px;
  }
}

.form-textarea {
  @extend %input;

  height: 100%;
  width: 100%;
  resize: vertical;
}

.form-error {
  position: relative;
  z-index: -1000;
  opacity: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $error-color;

  &.show {
    z-index: 1;
    opacity: 1;
    color: $error-color;
  }
}

.phone-select-wrapper {
  display: flex;

  .form-select {
    &__select {
      min-width: 14ch;
      max-width: 131px;
      height: 100%;

      &:not(:focus) {
        border-right: none;
      }

      &:focus + .form-input {
        border-left: none;
      }
    }
  }
}

.form-select {
  position: relative;

  &.nice-select-wrap {
    display: flex;

    &::after {
      content: none;
    }
  }

  &__select {
    @extend %input;
    appearance: none;
    width: 100%;

    padding-right: 36px;

    & > option {
      color: initial;
      font-weight: initial;
    }

    &.nice-select {
      $elementHeight: 38px;
      height: $elementHeight;
      padding-left: 16px;
      padding-right: 5px;
      width: 100%;
      font-size: 16px;
      line-height: $elementHeight;
      border-radius: var(--border-radius);
      overflow: hidden;
      position: relative;

      &.open {
        overflow: visible;
      }

      .nice-select-dropdown {
        width: 100%;
      }

      .option {
        word-wrap: normal;
        white-space: normal;
      }

      .current {
        position: absolute;
        top: calc(50% - calc($elementHeight / 2));
      }
    }
  }

  &::after {
    content: '';
    margin-top: -6px;
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 16px;
    border-bottom: 2px solid $color-htt-blue;
    border-right: 2px solid $color-htt-blue;
    pointer-events: none;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
  }
}

.form-general-error.error,
.onboarding-step #createAccountErrMsg.error,
.onboarding-step #loginAccountErrMsg.error,
.onboarding-step #forgotPwErrMsg.error,
.affiliate-form #createAccountErrMsg.error,
#downloadErrMsg.error,
.contact-form #contactFormErrMsg.error {
  margin-bottom: 1rem;
  padding: 6px 10px;
  color: #f00;
  background: #ffe8e8;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.view-password-toggle.active {
  display: none;
}

.view-password-toggle {
  position: absolute;
  top: 20%;
  right: 15px;
  cursor: pointer;
  // Need this for the js-view-password-toggle event listener, to stop <use> element having the click listener
  & > * {
    pointer-events: none;
  }
}

.htt-form {
  position: relative;

  &__fieldset {
    transition: opacity 0.25s ease;
    position: relative;
  }

  .form-success {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: opacity 1s ease;

    &__icon {
      @include sprite('chat-message');
      background-size: contain;
      width: 64px;
      height: 64px;
    }

    &__title,
    &__text {
      margin-top: 16px;
      text-align: center;
    }

    &__title {
      font-size: 18px;
      font-weight: 400;

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }
  }
}

@mixin gdpr-tickbox() {
  align-items: center;
  margin-bottom: 8px;

  @media (min-height: 1000px) {
    margin-bottom: 16px;
  }

  &-label {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 12px;
    display: flex;
    cursor: pointer;
    gap: 8px;
  }

  .disclaimer {
    font-size: 10px;
    line-height: 15px;
    display: inline-block;
    color: #7b7b7b;
  }

  .chkTerms {
    margin-right: 4px;
    width: 20px;
    height: 20px;
    position: relative;
    top: 2px;
  }
}
