/* stylelint-disable selector-max-type */
@import '~modern-normalize/modern-normalize.css';

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
button,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}

caption,
td,
th {
  text-align: left;
  font-weight: 400;
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}

a img {
  border: none;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
}

img:not([width]) {
  width: 100%;
  height: auto;
}

input[type='number'] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// hide recaptcha badge (disclamer displayed in the footer)

.grecaptcha-badge {
  opacity: 0;
  visibility: hidden;
}

// simplescrollbar override

.ss-scroll {
  background: #2c7bfd;
  width: 4px;
}
