@import '~flatpickr/dist/flatpickr.min.css';

.mfp-hide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  z-index: 10;

  .background-blur {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1000;
  }

  &.on {
    display: flex;
    position: fixed;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .background-blur {
      z-index: 1;
    }
  }
}

.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  opacity: 0.7;
  transition: all 0.25s ease;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;

  @media (min-width: 768px) {
    right: calc(35% + 15px);
  }

  @media (min-width: 1024px) {
    right: calc(35% + 25px);
  }

  svg {
    color: #9f9f9f;
    width: 25px;
    height: 25px;
    // transition: all 0.1s ease;
  }

  &:hover {
    border-color: $color-htt-blue;
    opacity: 1;
    background: $btn-hover-bg;

    svg {
      color: $color-htt-blue;
    }
  }
}

.popup {
  position: fixed;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  // left: 50%;
  top: 0;
  bottom: 0;
  overflow: scroll;
  opacity: 0.5;
  // transform: translate(-50%, -20%);
  transform: scale(0.7);
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  scrollbar-width: none;
  z-index: 10;

  &::-webkit-scrollbar {
    width: 0px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    background: transparent;
  }

  @media (max-width: 360px) {
    top: 6px;
  }

  @media (min-width: 501px) {
    margin: 0 auto;
    min-width: 500px;
  }

  @media (min-width: 600px) {
    top: 4%;
    width: 100%;
    max-width: 90%;
  }

  @media (min-width: 1200px) {
    width: 1120px;
  }

  &.animate-in {
    opacity: 1;
    // transform: translate(-50%, 0%);
    transform: scale(1);
  }

  &.hide-right {
    max-width: 750px;

    .popup-close {
      right: 15px;
    }

    .popup-container {
      display: flex;
    }

    .popup-container__right {
      display: none;
    }
  }

  &.hide-login {
    .popup-login-proposal {
      display: none;
    }
  }

  &.hide-steps {
    .popup-steps {
      display: none;
    }
  }
}

.modal-open {
  overflow: hidden;
  width: 100%;
}

.spinner-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 33;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.spinner {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 2px 1px 0px #2c7bfd;
  animation: spin 0.7s linear infinite;
  display: inline-block;
  transition: all 0.3s ease;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/********************************************************** #inputs **********************************************************/
.googleButton,
.facebookButton,
.appleButton {
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 7px 5px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;

  &:not(:hover) {
    // background: rgb(245 248 255 / 70%);
  }

  &:hover {
    background-color: #d2e3fc;
  }

  &:focus {
    box-shadow: 0 0 15px -2px rgb($color-htt-blue, 0.33);
  }

  .btn-text {
    display: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: $dark-text-color;
    margin-left: 12px;
    text-transform: none;
    letter-spacing: normal;
  }
}

.js_recaptcha {
  margin: 0 0 16px;
}

.js-register-link > * {
  pointer-events: none;
}

.createAccount {
  background: #fff;
  padding: 20px;

  &.transparent {
    background: transparent;
    padding: 0;
  }

  .form-grid {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 16px;
      grid-template-areas:
        'a b'
        'c d'
        'e e';
    }

    @media (min-height: 1000px) {
      grid-template-areas:
        'a b'
        'c d'
        'e e';
    }
  }

  .email {
    &:disabled {
      border-color: #eee;
      color: #bbb;
    }
  }

  .disclosure {
    align-items: center;
    margin-bottom: 8px;

    @media (min-height: 1000px) {
      margin-bottom: 16px;
    }

    &-label {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 12px;
      display: flex;
      cursor: pointer;
      gap: 8px;
    }

    .disclaimer {
      font-size: 10px;
      line-height: 15px;
      display: inline-block;
      color: #7b7b7b;
    }

    .chkTerms {
      margin-right: 4px;
      width: 20px;
      height: 20px;
      position: relative;
      top: 2px;
    }
  }

  .error {
    color: $error-color;
    margin-bottom: 10px;
  }

  &.is-social {
    .form-grid {
      @media (min-width: 501px) {
        grid-template-areas:
          'a b'
          'c c'
          'e e';
      }
    }
  }
}

.popup {
  .createAccount {
    padding: 0;
  }
}

.social-signin {
  position: relative;
  text-align: center;
  margin-bottom: 16px;

  .social-buttons-wrapper {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 16px;
  }

  .social-separator {
    &__text {
      color: #7b7b7b;
      padding: 0 10px;
      margin: 16px auto;
      display: inline;
      text-align: center;
      position: relative;
      z-index: 1;
      background: #fff;
    }

    &__line {
      border: none;
      border-top: 1px solid $light-grey-border-color;
      width: 100%;
      position: absolute;
      bottom: 11px;
      left: 0;
      right: 0;
      z-index: 0;
      margin: 0;
    }
  }
}

// New styles
.popup-container {
  min-height: 500px;
  display: flex;
  background-color: #fff;

  &__left {
    flex: 1 0 65%;
    position: relative;
  }

  &__right {
    @include sprite(
      'howtotrade-logo',
      (
        text-fill: $color-white,
      )
    );
    background-color: $dark-bg-color;
    background-size: 60%;
    flex: 0 0 35%;
    display: none;
    overflow: hidden;
    position: relative;
    min-height: 730px;

    @media (min-width: 768px) {
      display: block;
    }

    .popup-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.onboarding-step {
  display: none;
  padding: 60px 16px;
  position: relative;
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    padding: 40px;
  }

  @media (min-width: 600px) and (min-height: 1000px) {
    padding: 60px;
  }

  &.active {
    display: block;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;

    @media (min-height: 1000px) {
      margin-bottom: 16px;
    }

    .step-tracker {
      width: 100%;
      display: flex;
      gap: 12px;

      &__step {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        display: flex;
        background: #e1e5eb;
        align-items: center;
        justify-content: center;
        flex: 1 1 33%;
        height: 10px;

        &:nth-child(1) {
          border-radius: 5px 0 0 5px;
        }

        &:nth-child(2) {
          border-radius: 0;
        }

        &:nth-child(3) {
          border-radius: 0 5px 5px 0;
        }

        &.active {
          background: $color-htt-blue;
        }
      }
    }
  }

  &__title {
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
  }

  &__subtitle {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
  }

  &__text {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    color: #6f6f6f;

    @media (min-height: 1000px) {
      margin-bottom: 16px;
    }
  }

  &__panel {
    padding: 24px 0;
    border-top: 1px solid $light-grey-border-color;
    border-bottom: 1px solid $light-grey-border-color;
    margin: 24px 0;
  }

  .htt-btn {
    max-width: 100%;
    margin-bottom: 8px;

    &.appleButton {
      svg {
        color: #000;
      }
    }
  }

  .landing-success-type {
    .htt-btn {
      flex: 1 1 calc(50% - 16px);
    }
  }

  .cancel-btn {
    background: transparent;
    margin: 12px auto;
    display: block;
  }

  &.forgot-tab {
    .step1,
    .step2 {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .standard-login {
    &.hidden {
      display: none;
    }
  }
}

.accounts-selector {
  display: none;
  justify-content: space-between;
  gap: 8px 16px;
  flex-wrap: wrap;

  &.show {
    padding-bottom: 20px;
    padding-top: 32px;
    display: flex;
  }

  &-text {
    flex: 1 1 100%;
  }

  .account {
    flex: 1 1 calc(50% - 16px);

    .social-account-radio {
      appearance: none;
      display: none;
    }

    .social-account-label {
      background: #ffffff;
      border: 1px solid $light-grey-border-color;
      padding: 12px 16px;
      color: #2c3d55;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.book-webinar {
  .book-webinar-type {
    display: none;
  }

  .book-webinar-type {
    &_missed {
      @media (max-width: 767px) {
        .onboarding-step__top {
          svg {
            width: 30%;
            height: auto;
          }
        }
      }
    }
  }

  &[data-type='default'] {
    .book-webinar-type_default {
      display: block;
    }
  }

  &[data-type='missed'] {
    .book-webinar-type_missed {
      display: block;
    }
  }
}

.landing-success {
  .landing-success-type {
    display: none;
    height: 100%;
  }

  &[data-type='monitor'] {
    .landing-success-type_monitor {
      display: block;
    }
  }

  &[data-type='tradingPlan'] {
    .landing-success-type_tradingPlan {
      display: block;
    }
  }

  &[data-type='questions'] {
    .landing-success-type_questions {
      display: block;
    }
  }
}
