.th-blog {
  position: relative;
  padding-top: var(--header-height);

  &__body {
    display: flex;
    margin: 32px auto;
  }

  &__posts {
    display: flex;
    flex-wrap: wrap;
  }

  &__post {
    flex: 1 1 50%;
    min-width: 240px;
    padding: 16px;
  }

  &__post_img {
    overflow: hidden;
  }

  &__post_img:hover img {
    transform: scale3d(1.1, 1.1, 1);
    transition: opacity 0.35s, transform 0.35s;
  }

  &__post_img img {
    width: 100%;
    height: auto;
  }

  &__post > div {
    box-shadow: 0 2px 4px 0 rgba(14, 31, 53, 0.12);
    height: 100%;
  }

  &__post_msg {
    text-align: center;
    padding: 24px 8px 0;
  }

  &__post_msg h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__post_msg h2 a {
    color: #171717;
  }

  &__post_msg-cat {
    font-size: 12px;
  }

  &__post_msg-date {
    font-size: 12px;
    color: #666666;
  }

  &__post-featured {
    padding-top: 65px;
    position: relative;
  }

  &__post-featured_img {
    border-radius: var(--border-radius);
    overflow: hidden;
  }

  &__post-featured_img img {
    width: 100%;
    height: 360px;
    object-fit: cover;
  }

  &__post-featured_msg {
    position: relative;
    margin: -120px 16px 0;
    padding: 24px 32px;
    border-radius: var(--border-radius);
    box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  &__post-featured_msg h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  &__post-featured_msg h2 a {
    color: #171717;
  }

  &__post-featured_msg-cat {
    font-size: 12px;
  }

  &__post-featured_msg-date {
    font-size: 14px;
    font-style: italic;
    color: #666666;
  }

  &__post-featured_msg-excerpt {
    font-size: 14px;
    line-height: 1.71;
    color: #666666;
  }

  &__btn {
    text-align: center;
    padding: 24px 16px;

    .th-button-primary {
      font-size: 14px;
    }
  }

  &__sidebar {
    flex: 0 0 324px;
    padding: 16px 0 16px 24px;
  }

  &__sidebar .title {
    font-size: 18px;
    font-weight: 600;
    color: #171717;
  }

  &__categories li {
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 4px;
    font-size: 14px;
    color: #666666;
  }

  &__sidebar__banner {
    margin: 32px 0;
    text-align: center;
  }

  &__content__banner {
    display: none;
    margin: 32px 0 0;
    text-align: center;
  }

  .th-register-cta {
    margin: 32px 0;
  }

  &__cat-title h1 {
    padding: 62px 0;
    text-align: center;
    font-size: 55px;
    font-weight: 700;
    font-variant: all-small-caps;
    line-height: 42px;
  }

  @media (max-width: 767px) {
    &__sidebar {
      display: none;
    }

    &__sidebar__banner {
      display: none;
    }

    &__content__banner {
      display: block;
    }
  }
}
