// root styles

:root {
  --num-grid-columns: 4;
  // --page-max-width: 1200px;
  // --page-gutter: 16px;
  --page-max-width: 1240px;
  --page-gutter: 16px;
  --responsive-rem: 0.8vmin;
  --header-height: 79px;
  --ribbon-height: 0px;
  --sticky-height: calc(var(--header-height) + var(--ribbon-height));
  --small-section-gap: 48px;
  --basic-section-gap: 68px;
  --extended-section-gap: 96px;
  --border-radius: 5px;

  // Fonts
  --default-font: 'Poppins', 'Trebuchet MS', Verdana, sans-serif;
  --heading-font: 'Poppins', 'Trebuchet MS', Verdana, sans-serif;
  --writing-font: 'Rock Salt', 'Trebuchet MS', Verdana, sans-serif;

  @media screen and (min-width: 768px) {
    --basic-section-gap: 96px;
    --page-gutter: 70px;
  }

  @media screen and (min-width: 1024px) {
    --basic-section-gap: 120px;
    --responsive-rem: 0.4938vh;
    --extended-section-gap: 240px;
    --page-gutter: 45px;
  }

  @media screen and (min-width: 1200px) {
    --page-gutter: 70px;
  }
}
