.th-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  &__div {
    padding: 40px 98px;
    border-radius: 6px;
    box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08),
      0 31px 41px 0 rgba(33, 43, 54, 0.2);
    background-color: #ffffff;
    text-align: center;
  }

  img {
    width: auto;
    height: 185px;
  }

  .title {
    margin: 16px 0;
    font-size: 26px;
    font-weight: 600;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    color: #1b295e;
  }

  .tagline {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    color: #758795;
  }

  @media (max-width: 767px) {
    &__div {
      padding: 32px;
      margin: 16px;
    }
  }

  &-video {
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);

    &img {
      margin: 28px 0 33px;
    }

    &__popup {
      width: 80%;
      height: 80%;
      background-color: transparent;
      padding: 0;
      border: 0;
      box-shadow: none;
    }

    &__close {
      position: absolute;
      right: 16px;
      top: 64px;
      cursor: pointer;
    }

    &__close svg {
      width: 52px;
      height: 52px;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }

    @media (max-width: 768px) {
      &__popup {
        width: 90%;
      }
    }
  }
}
