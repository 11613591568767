@use 'sass:math';

@mixin container(
  $mw: var(--page-max-width),
  $pad: var(--page-gutter),
  $mob: 1024px
) {
  width: 100%;
  padding: 0 $pad;
  margin-right: auto;
  margin-left: auto;
  // max-width: calc($mw + $pad * 2);
  max-width: $mw;

  &.full-mobile {
    padding: 0;

    @media (min-width: $mob) {
      padding: 0 var(--page-gutter);
    }
  }
}

@mixin inset($top: 0, $right: 0, $bottom: 0, $left: 0) {
  inset: $top $right $bottom $left;

  @supports not (inset: 0) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
}

.htt-container {
  @include container;
}

@mixin sprite-mask($name) {
  $sprite: map-get($sprites, $name);

  mask-image: url($sprite);
}

@mixin ultrawide-bg-fix($add-filter: true) {
  max-width: 1440px;
  left: 50%;
  transform: translateX(-50%);

  @if $add-filter {
    @media (min-width: 1441px) {
      @include bg-filter(
        linear-gradient(90deg, rgba(2, 6, 7, 0) 70%, $dark-bg-color 100%),
        'before'
      );
    }
  }
}

@mixin bg-filter($bg, $pseudo-element: 'after') {
  &::#{$pseudo-element} {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    background: $bg;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 233, 147, 0.4);
  }

  100% {
    box-shadow: 0 0 0 8px rgba(0, 233, 147, 0);
  }
}

@mixin button-click {
  // transform: translateY(1px);

  &::after {
    box-shadow: 0 0 0 0 rgb($text-color, 0.33);
    top: 0;
    opacity: 1;
    transition: 0s;
  }
}

.htt-btn {
  $this: &;

  position: relative;
  display: inline-block;
  padding: 17px 22px;

  background: $color-htt-blue;
  color: $text-color;
  border: 1px solid $color-htt-blue;
  border-radius: 0;
  user-select: none;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 1.15;
  white-space: nowrap;
  transition-property: background-color, box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: linear;
  max-width: 100%;
  width: 100%;
  border-radius: var(--border-radius);

  @media (min-width: 576px) {
    max-width: 275px;
  }

  &:disabled {
    opacity: 0.72 !important;
    background: rgb($color-htt-blue, 0.4);
  }

  &:not(:disabled):hover {
    background-color: $btn-hover-bg;
    color: $color-htt-blue;
    transition-duration: 0.1s;
    cursor: pointer;
  }

  &:not(:disabled):active {
    @include button-click;
    background-color: $btn-active-bg;
  }

  &:focus {
    box-shadow: 0 0 15px -2px $color-htt-blue;
  }

  &.btn-outline {
    background-color: $bg-color;
    color: $color-htt-blue;

    &.btn-mask-icon {
      &::after {
        background-color: $color-htt-blue;
      }
    }
  }

  &.btn-outline-white {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
  }

  &.loading::after {
    content: '';
    position: relative;
    top: 4px;
    margin-top: -8px;
    margin-right: 12px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-top-color: $text-color;
    border-right-color: $text-color;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  .btn-caption {
    flex-basis: 100%;
  }

  &.alt-cta {
    background: rgba(44, 123, 253, 0.2);
    color: $color-htt-blue;
    border-color: $color-htt-blue;
  }

  &.centered {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  &.btn-max-width {
    max-width: 100%;
    width: 100%;
  }

  &.btn-wide {
    max-width: 100%;
    width: auto;
  }

  &.attention {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0e08ffde, #000 70%)
      right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 1.5s infinite;
  }

  &.btn-to-home {
    text-decoration: none;

    &::after {
      -webkit-mask-size: 30px;
      mask-size: 30px;

      @include sprite-mask('arrow-right-flat');
    }
  }

  &.green {
    border: 1px solid #25e075;
    background: #22c076;
    color: #fff;

    &:hover {
      background: #fff;
      border: 1px solid #25e075;
      color: #22c076;
    }

    &:not(:disabled):hover {
      &::after {
        background-color: #22c076;
      }
    }

    &:focus {
      box-shadow: 0 0 15px -2px #22c076;
    }

    &:active {
      color: #22c076;

      &::after {
        background-color: #22c076;
      }
    }
  }

  &.sm-join {
    max-width: 330px;
    text-transform: none;
  }

  &.nav-registered {
    max-width: 180px;
  }

  &.nav-unregistered {
    max-width: 130px;
  }
}

.secondary-signup {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;

  a {
    color: #757575;
    text-decoration: underline;
  }
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.link-mask-icon,
.btn-mask-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    mask-repeat: no-repeat;
    mask-position: center;
    flex-shrink: 0;
  }

  .btn-caption {
    flex-basis: initial;
  }
}

.link-mask-icon {
  color: $link-mask-color;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;

  &:not(:disabled):hover {
    color: $link-mask-hover-color;
    text-decoration: none;

    &::after {
      background-color: $link-mask-hover-color;
    }
  }

  &::after {
    width: 18px;
    height: 18px;
    background-color: $link-mask-color;
  }

  .btn-caption {
    padding-left: 4px;
  }
}

.btn-mask-icon {
  padding-top: 13px;
  padding-bottom: 13px;

  &:not(:disabled):hover {
    &::after {
      background-color: $color-htt-blue;
    }
  }

  &::after {
    width: 24px;
    height: 24px;
    background-color: $text-color;
  }

  &.loading::after {
    margin-top: 0;
    margin-right: 2px;
    top: 0;
    width: 22px;
    height: 22px;
    mask-image: none;
    background: none;
  }

  .btn-caption {
    padding-right: 10px;
  }
}

.big-number {
  white-space: nowrap;

  &__digit {
    padding: 0 10px;
    position: relative;
    font-size: 46px;
    font-weight: 700;
    background: linear-gradient(165.34deg, #a4efff 43.98%, #2074ff 89.67%);

    background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (min-width: 768px) {
      font-size: 72px;
    }

    @media (min-width: 1024px) {
      font-size: 120px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 1px;
      height: 80%;
      transform: translateY(20%);
      background: linear-gradient(
        rgba(69, 127, 221, 0) 0%,
        rgba(69, 127, 221, 1) 50%,
        rgba(69, 127, 221, 0) 100%
      );
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

@mixin aspect-ratio-wrap($ratio) {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: $ratio;
  }

  &__inner {
    position: absolute;

    @include inset;
  }
}

// mask icons for buttons

$_mask-icons-list: play list ui-chat ui-join ui-download ui-external
  chevron-right; // add more if needed

@each $letter in $_mask-icons-list {
  .mask-icon-#{$letter}::after {
    @include sprite-mask('#{$letter}');
  }
}

.mask-icon-ui-join::after {
  mask-size: 19px;
}

.mask-icon-ui-download::after {
  mask-size: 16px;
}

.mask-icon-ui-external::after {
  mask-size: 15px;
}

// default play button

@mixin play-button($with-caption: false) {
  @if $with-caption {
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  &::after {
    content: '';

    @include sprite('placeholder-play');

    @if $with-caption {
      margin-bottom: 10px;
    } @else {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    width: 35px;
    height: 35px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(3px);
    border: 4px solid #fff;
    border-radius: 50%;
    transform-origin: center;
    transition: all 0.25s ease;
  }

  &:hover {
    &::after {
      @if $with-caption {
        transform: scale(1.1);
      } @else {
        transform: translate(-50%, -50%) scale(1.2);
      }
      opacity: 1;
    }
  }
}

// vimeo placeholder

.vimeo-player {
  @include aspect-ratio-wrap(56.25%);

  &__inner {
    cursor: pointer;
    height: 100%;

    &[data-placeholder] {
      @include play-button;
    }

    iframe,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 1px);
      object-fit: cover;
    }

    img {
      max-width: unset;
    }
  }
}

.chat-video {
  width: 100%;
  margin-bottom: 24px;
  box-shadow: 0px 0 0px 2px $dark-grey-border-color;
}

.video-modal-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  text-align: left;
  padding: 24px;
  border: 1px solid #fff;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .hero-watch-video {
    position: relative;
    cursor: pointer;

    @include play-button();
    background: url('/static/img/economic-calendar/economic-calendar-howto-placeholder.png');
    background-repeat: no-repeat;
    width: 150px;
    height: 90px;
    margin-right: 24px;
    background-size: cover;
    color: #fff;
    align-self: flex-start;
    padding-left: 24px;
    margin-top: 16px;

    @media (min-width: 768px) {
      padding-left: 0;
      color: transparent;
      align-self: center;
      margin-top: 0;
    }

    &::after {
      width: 16px;
      height: 16px;
      left: 8px;
      top: 10px;
      border: 1px solid #fff;

      @media (min-width: 768px) {
        width: 35px;
        height: 35px;
        left: 50%;
        top: 50%;
        border: 2px solid #fff;
      }
    }
  }
}

.mobile-menu-expander {
  margin: 0;
  padding: 20px 0 0;
  width: 100%;
  border: 0;
  background: none;
  color: var(--menu-text-color);
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: none;
  }

  &.active {
    font-weight: 600;
    color: var(--menu-text-color-active);

    > svg {
      transform: rotate(-180deg);
    }
  }

  > svg {
    transition: transform 0.2s ease;
  }
}

.mobile-menu-expandable {
  overflow: hidden;
  transition: max-height 0.3s ease;
  height: 100%;
  max-height: 0;

  @media (min-width: 1024px) {
    height: auto !important;
  }
}

.menu-expander {
  display: flex;
}

.menu-expandable {
  overflow: hidden;
  transition: height 0.25s ease;
  height: 0;
}

.svg-icon {
  position: relative;
  pointer-events: none;
  fill: currentColor;
}

.ps-scrollbar-y-rail,
.ps-scrollbar-x-rail {
  box-sizing: border-box;
}

// cookie popup
// new cookie
.cookie-consent {
  visibility: hidden;
  opacity: 0;
  padding: 16px;
  position: fixed;
  width: 345px;
  left: 15px;
  bottom: 15px;
  z-index: 9999;
  background: $dark-bg-color;
  color: $text-color;
  border-radius: 0px;
  letter-spacing: 0.02em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  user-select: none;

  &.show {
    display: flex;
    visibility: visible;
    opacity: 1;
    z-index: 999;
  }

  &__content {
    padding: 24px;

    &__title {
      margin-top: 12px;
      font-weight: 500;
    }

    &__text {
      margin-top: 8px;
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  &__buttons {
    padding: 24px 0;
    border-top: 1px solid #e5e5e5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .htt-btn {
      flex: 1 1 calc(50% - 16px);
    }
  }
}

.basic-list {
  $this: &;

  padding-top: 16px;
  padding-left: 20px;
  list-style: disc;

  &__item {
    // margin-right: 32px;
    break-inside: avoid-column;

    &::marker {
      color: $color-htt-blue;
      font-size: 16px;
      line-height: 1;
    }

    &:not(:first-child) {
      margin-top: 8px;

      @media (min-width: 768px) {
        margin-top: 16px;
      }
    }
  }

  &.checkmarks {
    padding-left: 0;

    #{$this}__item {
      padding-left: 24px;
      position: relative;
      list-style: none;

      &::before {
        content: '';
        display: block;
        width: 13px;
        height: 9px;
        position: absolute;
        left: 0;
        top: 8px;

        @include sprite(
          'list-checkmark',
          (
            fill: $color-htt-blue,
          )
        );
      }
    }
  }

  &.arrows {
    #{$this}__item {
      position: relative;
      list-style: none;

      &::before {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        position: absolute;
        left: -20px;
        top: 4px;

        @include sprite('rightarrow');
      }
    }
  }
}

.check-list {
  max-width: 100%;
  margin: 0;

  &.blue {
    .check-simple-icon {
      color: $color-htt-blue;
    }
  }

  &.dark {
    .check-simple-icon {
      color: $dark-text-color;
    }
  }

  .check-simple-icon {
    margin-right: 10px;
    width: 16px;
    height: 24px;
    color: #fff;
    flex: 0 0 16px;
  }
}

.numbered-list {
  list-style-type: decimal;
  padding-left: 30px;

  li {
    margin-bottom: 16px;
    padding-left: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .contents-list {
      text-decoration: underline;
      color: #0f0f0f;
    }
  }
}

.numbered-list-fancy {
  margin-left: 56px;
  position: relative;

  &::before {
    content: '';
    width: 2px;
    height: calc(100% - 10%);
    position: absolute;
    left: -39px;
    top: 0;
    background-color: $color-htt-blue;
    z-index: 0;
  }

  &__item {
    margin-bottom: 32px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      position: absolute;
      left: -56px;
      top: -5px;
      background-color: $color-htt-blue;
      border-radius: 50%;
      color: #fff;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      line-height: 19px;
    }

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        &::before {
          content: '#{$i}';
        }
      }
    }
  }
}

.htt-countdown {
  $this: &;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  transition: opacity 0.55s ease-out;

  &.has-days {
    grid-template-columns: repeat(4, 1fr);

    #{$this}-block {
      &:first-child {
        display: flex;
      }
    }
  }

  &.hide-seconds {
    #{$this}-block {
      &:last-child {
        display: none;
      }
    }
  }

  &-block {
    padding: 14px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #d1e3ff;
    color: $color-htt-blue;
    font-size: 12px;

    &::before {
      content: attr(data-value);
      font-size: 32px;
      line-height: 1;
      font-weight: 500;
    }

    &:first-child {
      display: none;
    }

    &__unit {
      //margin-top: 4px;
    }
  }
}

@mixin countdown($light) {
  .htt-countdown {
    &-block {
      @if $light {
        background: #d1e3ff;
        color: $color-htt-blue;
      } @else {
        background: #272c32;
        color: #fff;
      }
    }
  }
}

@mixin countdownstyle($background, $color, $margin, $style) {
  // if $style == block then use the default block style like on the promo exit popup
  // TODO rework all occurrences of countdown to tidy up the code, so that we have a few different components
  @if $style == 'block' {
  } @else {
    width: min-content;
    margin: 0 auto;

    @media (min-width: 768px) {
      margin: $margin;
    }

    .htt-countdown-block {
      padding: 0 5px;
      background: $background;
      position: relative;
      color: $color;
      font-size: 16px;

      &::before {
        font-family: var(--heading-font);
        font-size: 40px;
      }

      &::after {
        content: ':';
        position: absolute;
        right: -9px;
        bottom: 17px;
        font-family: var(--heading-font);
        font-size: 40px;
      }

      &:nth-child(2) {
        padding-left: 0;
      }

      &:last-child {
        padding-left: 0;

        &::after {
          content: none;
        }
      }
    }
  }
}
// grid area letters

$_letters-list: a b c d e f g h i j k l m; // add more if needed

@each $letter in $_letters-list {
  .grid-area-#{$letter} {
    grid-area: $letter;
  }
}

// webinar grab

.webinar-grab-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media (min-width: 600px) and (max-width: 1023px) {
    grid-template-columns: 2fr 1fr;
    grid-gap: 0;

    .form-input {
      border-right: none !important;
    }
  }

  .htt-btn {
    max-width: unset;
  }
}

// easy as 1-2-3 cards
@mixin its-easy-card($number-color: #72a5ff, $title-color: $color-htt-blue) {
  .its-easy-card {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 110px 0 0;
    min-height: 272px;

    &:nth-child(1) {
      @include sprite(
        'easy-card-1',
        (
          'color': $number-color,
        )
      );
    }

    &:nth-child(2) {
      @include sprite(
        'easy-card-2',
        (
          'color': $number-color,
        )
      );
    }

    &:nth-child(3) {
      @include sprite(
        'easy-card-3',
        (
          'color': $number-color,
        )
      );
    }

    &__icon {
      position: absolute;
      z-index: -1;
      top: 0;
    }

    &__title {
      color: $title-color;
      font-size: 24px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 8px;
    }
  }
}

// ribbon logo slider

@mixin logo-slider($var-name, $animation-name, $height) {
  .logo-slider {
    @keyframes running-line-#{$animation-name} {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(calc(-1 * var(#{$var-name})));
      }
    }

    width: 100%;
    height: $height;
    overflow: hidden;
    position: relative;

    &__track {
      // track width is calculated by Smarty and being put in style attribute
      animation: running-line-#{$animation-name} 28s linear infinite;
      display: flex;
      align-items: center;
    }

    &__slide {
      margin: 0 20px;
      text-align: center;

      img {
        display: block;
      }
    }
  }
}

// image with cropped colored bg
@mixin image-with-cropped-bg($height: null) {
  position: relative;

  @if $height {
    height: $height;
  }

  > img,
  > picture > img {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    display: block;
    width: auto;
    height: 100%;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 85%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $color-htt-blue;
  }
}

// image with floating square on a corner

@mixin image-with-floating-square($pos: 1) {
  $offset: 13%;

  display: flex;
  align-items: flex-start;
  position: relative;

  &::after {
    content: '';
    display: none;
    width: 31%;
    padding-top: 31%;
    position: absolute;

    @if $pos == 1 {
      top: 0;
      left: 0;
      transform: translate(-$offset, -$offset);
    } @else if $pos == 2 {
      top: 0;
      right: 0;
      transform: translate($offset, -$offset);
    } @else if $pos == 3 {
      bottom: 0;
      right: 0;
      transform: translate($offset, $offset);
    } @else if $pos == 4 {
      bottom: 0;
      left: 0;
      transform: translate(-$offset, $offset);
    }

    background: $color-htt-blue;
    opacity: 0.24;

    @media (min-width: 768px) {
      display: block;
    }
  }
}

@for $index from 1 through 4 {
  .img-floating-square-#{$index} {
    @include image-with-floating-square($index);
  }
}

.trustpilot-reviews {
  display: flex;
  align-items: flex-end;
  height: 30px;

  &.centered {
    justify-content: center;
  }

  &.centered-lg {
    @media (min-width: 1024px) {
      justify-content: center;
    }
  }

  &.left-md {
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }

  &.left-lg {
    @media (min-width: 1024px) {
      justify-content: flex-start;
    }
  }

  svg {
    margin-left: 12px;
    align-self: center;
  }
}

@mixin circle-shadow($size) {
  position: relative;

  & > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: #2c7bfd;
    opacity: 0.3;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    filter: blur(100px);
    z-index: 0;

    @media (min-width: 500px) {
      @if $size == 'small' {
        width: 235px;
        height: 235px;
      } @else if $size == 'medium' {
        width: 350px;
        height: 350px;
      } @else if $size == 'large' {
        width: 450px;
        height: 450px;
      }
    }
  }
}

.circle-shadow-small {
  @include circle-shadow('small');
}

.circle-shadow-medium {
  @include circle-shadow('medium');
}

.circle-shadow-large {
  @include circle-shadow('large');
}

.cursor-pointer {
  cursor: pointer;
}

.full-width-mob-img {
  // Overflow by margins of the page gutter making it full width on mob
  margin-left: calc(var(--page-gutter) * -1);
  margin-right: calc(var(--page-gutter) * -1);
  width: calc(100% + var(--page-gutter) + var(--page-gutter)) !important;
  // remove border radius too by force if it has any
  border-radius: 0;

  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
  }
}

.border-img-lg-8 {
  @media (min-width: 1024px) {
    border-radius: 8px;
  }
}

.border-img-md-8 {
  @media (min-width: 768px) {
    border-radius: 8px;
  }
}

@mixin rounded-top-bg($color, $position) {
  &::after {
    content: '';
    position: absolute;

    @if $position == top {
      top: 0;
    } @else {
      bottom: 0;
    }
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 0 0 250px $color;
    z-index: -1;
  }

  &::before {
    content: '';
    position: absolute;

    @if $position == top {
      top: 0;
    } @else {
      bottom: 0;
    }
    right: 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 0 0 250px $color;
    z-index: -1;
  }
}

@keyframes animate-live-icon {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animate-live-icon-text {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.toggle-plus-minus {
  background-color: #ffefd7;
  width: 38px;
  height: 21px;
  border-radius: 10.5px;
  box-shadow: inset 0px 0px 8px 3px rgb(0 0 0 / 10%);
  position: relative;
  transition: 0.3s ease;

  &::after {
    content: '';
    width: 17px;
    height: 17px;
    position: absolute;
    top: 2px;
    left: 2px;
    background: url('/static/img/homepage/toggle-off.svg');
    border-radius: 50%;
    transform: translateX(0);
    transition: 0.3s ease;
    box-shadow: -2px 1px 6px rgba(0, 0, 0, 0.25);
  }

  &.active {
    background-color: #df8500;

    &::after {
      background: url('/static/img/homepage/toggle-on.svg');
      transform: translateX(17px);
    }
  }
}

.disclaimer-obligation {
  color: #91a2bf;
  display: block;
  text-align: center;
  max-width: 285px;
  width: 100%;

  &.success {
    margin-left: auto;
    margin-right: auto;
  }
}

.hero-social-email-login {
  width: 100%;

  .social-signin {
    position: relative;

    .social-buttons-wrapper {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: repeat(3, 1fr);

      .htt-btn.btn-outline {
        background: #f5f8ff;
        border: 1px solid #dadce0;
        flex: 1 1 auto;
        height: 41px;
        margin-top: 0;

        &.appleButton {
          svg {
            color: #000;
          }
        }
      }
    }

    &.last {
      .social-separator {
        margin: 16px 0;
      }
    }

    .social-separator {
      margin: 20px 0;
      border-top: 1px solid #bfbfbf;

      @media (min-height: 1000px) {
        margin: 23px 0;
      }

      &__text {
        color: #bfbfbf;
        position: relative;
        padding: 0 10px;
        width: 100%;
        display: block;
        text-align: center;
        background: transparent;

        @media (min-height: 1000px) {
          padding: 5px 10px;
        }

        &::before {
          content: '';
          width: 45%;
          height: 1px;
          background: #bfbfbf;
          position: absolute;
          top: 50%;
          left: 0;
        }

        &::after {
          content: '';
          width: 45%;
          height: 1px;
          background: #bfbfbf;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
    }
  }

  .form-group {
    display: flex;
    margin-bottom: 0;
    flex-direction: column;

    @media (min-width: 576px) {
      flex-direction: row;
    }
  }

  .htt-hero-form-btn {
    position: relative;
    top: 0;
    right: 0;
    background: $color-htt-blue;
    color: $text-color;
    border: 1px solid $color-htt-blue;
    height: 50px;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @media (min-width: 576px) {
      position: absolute;
      padding: 0 15px;
    }
  }

  .form-input {
    height: 50px;
  }

  &.dark {
    .social-buttons-wrapper {
      .htt-btn.btn-outline {
        background: #333334;
        border: 1px solid $dark-grey-border-color;

        .btn-text {
          color: #fff;
        }

        &.appleButton {
          svg {
            color: #fff;
          }
        }
      }
    }

    .social-separator {
      border-top: 1px solid #7a7a7a;

      &__text {
        color: #7a7a7a;
        background-color: $dark-fg-color;
      }
    }

    .form-input {
      background: #333334;
      color: #fff;
      border: none;
    }
  }
}

.email-grabber-generic {
  position: relative;
  width: 100%;

  @media (min-width: 1024px) {
    width: auto;
  }

  &-form {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 550px;
    margin: 0 auto;
    grid-gap: 16px;

    @media (min-width: 1024px) {
      grid-template-columns: 2fr 1fr;
      grid-gap: 0;
      margin: 0;
    }

    &.hidden {
      display: none;
    }

    .form-group {
      display: flex;
      margin-bottom: 0;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }

    .form-input {
      background: #fff;
      height: 50px;
      width: 100%;
      border: 1px solid $light-grey-border-color;
      padding-left: 10px;
      border-radius: var(--border-radius);
      color: $dark-text-color;
      -webkit-text-fill-color: $dark-text-color;

      @media (min-width: 1024px) {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
      }
    }

    .form-error {
      margin-top: 8px;
    }

    .email-grabber-btn {
      max-width: 100%;
      height: 50px;

      @media (min-width: 1024px) {
        border-radius: 0 5px 5px 0;
        max-width: 200px;
      }
    }
  }

  &-success {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 50px;
    flex-direction: column;
    opacity: 1;

    @media (min-width: 576px) {
      flex-direction: row;
    }

    &.hidden {
      display: none;
    }

    &-icon {
    }

    &.text-center {
      justify-content: center;
    }

    &.successAnimateOut {
      opacity: 0;
      transition: 1.5s;
    }
  }
}

@mixin repeating-color-border-left($width) {
  $colors-count: length($colors-panels-list);

  @for $i from 1 through $colors-count {
    $color-index: if($i <= $colors-count, $i, $i - $colors-count);
    &:nth-child(#{$colors-count}n + #{$i}) {
      border-left: $width solid nth($colors-panels-list, $color-index);
    }
  }
}

@mixin splide-slider-styling($numCards, $fgLight: true) {
  .splide-wrapper {
    padding-bottom: 85px;
    overflow: hidden;
  }

  .splide {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    position: relative;

    @media (min-width: 1024px) {
      display: block;
    }

    &__track {
      overflow: visible;
    }

    &__slide img {
      position: relative;
      top: -2px;
      z-index: 0;
    }

    &__arrows {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0;
      bottom: -100px;
      z-index: 2;
      width: 100%;
    }

    &__arrow {
      @if $fgLight {
        @include sprite(
          'splide-arrow',
          (
            'color': #fff,
          )
        );

        &:hover {
          @include sprite(
            'splide-arrow',
            (
              'color': #fff,
            )
          );
        }
      } @else {
        @include sprite(
          'splide-arrow',
          (
            'color': $dark-text-color,
          )
        );

        &:hover {
          @include sprite(
            'splide-arrow',
            (
              'color': $dark-text-color,
            )
          );
        }
      }
    }

    &__pagination {
      position: absolute;
      bottom: -40px;

      li {
        flex: 1 1 math.percentage(math.div(1, $numCards));

        @media (min-width: 768px) {
          flex: 1 1 33%;
        }

        @media (min-width: 1024px) {
          flex: 1 1 50%;
        }
      }

      &__page {
        padding: 0;
        margin: 0;
        border-radius: 0;
        width: 100%;
        height: 2px;
        opacity: 0.3;

        @if $fgLight {
          background: #fff;
        } @else {
          background: $dark-text-color;
        }

        &.is-active {
          transform: scale(1);

          @if $fgLight {
            background: #fff;
          } @else {
            background: $dark-text-color;
          }
        }
      }
    }
  }
}

.external-link-buttons {
  padding: 27px 24px;
  background: $dark-fg-color;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;

  @include repeating-color-border-left(4px);
}

.tooltip-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  .information-tooltip-text {
    display: none;
    z-index: 2;
    background: #3f3f41;
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-80%);
    padding: 10px;
    font-size: 12px;
    border-radius: 10px;
    width: 200px;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #3f3f41;
      position: absolute;
      top: -10px;
      left: 80%;
      transform: translateX(-50%);
    }
  }

  .information-tooltip-icon {
    cursor: pointer;

    &:hover + .information-tooltip-text {
      display: block;
    }

    & > * {
      pointer-events: none;
    }
  }
}

.guarantee {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding-top: 32px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.speech-popup {
  display: flex;
  align-items: flex-start;
  background: #fff;
  padding: 8px;
  position: absolute;
  border-radius: 6px;
  color: $dark-text-color;
  width: 275px;
  font-size: 12px;
  gap: 16px;
  top: 34%;
  left: 2%;
  opacity: 0;
  transform: translateY(500px) scale(0.1);

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    position: absolute;
    bottom: -9px;
    left: 70%;
  }

  &.animate-in {
    animation: ease-in-out animate-in-text-bubble 0.4s;
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  &__tag {
    background: $color-htt-blue;
    color: #fff;
    padding: 0 3px;
  }

  &__text {
    margin-top: 3px;
  }
}

@keyframes animate-in-text-bubble {
  0% {
    opacity: 0;
    transform: translateY(500px) scale(0.1);
  }

  60% {
    transform: translateY(-50px) scale(1.2);
    opacity: 1;
  }

  100% {
    transform: translateY(0) scale(1);
  }
}

.terms-check {
  a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
  }
}

@keyframes changeZIndex {
  0% {
    z-index: -99;
  }

  100% {
    z-index: 1000;
  }
}

@keyframes changeOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin info-block($video-placeholder: none, $width: 87px, $height: 87px) {
  border-radius: var(--border-radius);
  border: 1px solid rgba(183, 212, 251, 0.4);
  background: #f5f8ff;
  padding: 8px;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  font-weight: 700;
  font-size: 19px;

  &__video {
    @include play-button();
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    align-self: flex-start;
    position: relative;
    display: flex;
    width: $width;
    height: $height;
    order: 2;
    border-radius: var(--border-radius);
    cursor: pointer;

    @media (min-width: 768px) {
      order: 1;
      color: transparent;
      align-self: center;
      background: url($video-placeholder) no-repeat;
    }

    &::after {
      width: 16px;
      height: 16px;
      left: 8px;
      top: 10px;
      border: 1px solid #fff;

      @media (min-width: 768px) {
        width: 35px;
        height: 35px;
        left: 50%;
        top: 50%;
        border: 2px solid #fff;
      }
    }
  }

  &__content {
    order: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 768px) {
      order: 2;
    }

    &__text {
      max-width: 450px;
    }

    .htt-btn {
      align-self: center;
    }
  }

  &.full {
    width: 100%;
    max-width: 100%;
  }
}

.info-block {
  @include info-block;
}

.link-arrow-right-animation {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;

  .icon-arrow {
    transition: 0.3s;
  }

  &:hover {
    .icon-arrow {
      transform: translateX(3px) scale(1.1);
    }
  }
}

@mixin logo-slider($var-name, $animation-name, $height) {
  .logo-slider {
    @keyframes running-line-#{$animation-name} {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(calc(-1 * var(#{$var-name})));
      }
    }

    grid-column: span 12;
    width: 100%;
    height: $height;
    overflow: hidden;
    position: relative;

    &__track {
      // track width is calculated by Smarty and being put in style attribute
      animation: running-line-#{$animation-name} 28s linear infinite;
      display: flex;
      align-items: center;
    }

    &__slide {
      margin: 0 20px;
      text-align: center;

      img {
        display: block;
      }
    }
  }
}

@mixin broker-logos {
  .broker-logos {
    position: absolute;
    bottom: 24px;

    @include logo-slider('--brokers-track-width', 'brokers', 37px);

    @media (min-width: 1024px) {
      bottom: 40px;
    }

    .logo-slider {
      opacity: 0.6;

      @media (min-width: 1024px) {
        opacity: 0.33;
      }

      &__slide {
        transform: scale(0.8);

        @media (min-width: 1024px) {
          transform: scale(1);
        }
      }
    }
  }
}

.powered-by-track-a-trader {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }

  &__text {
    font-size: 13px;
  }

  &__logo {
  }
}

.info-panel__blue-border-left {
  background: #ffffff;
  box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.15);
  padding: 16px 20px;
  border-left: 4px solid $color-htt-blue;
  border-radius: var(--border-radius);

  &.grey-bg {
    background: #f5f5f5;
  }

  &.transparent {
    background: transparent;
    border-top: 1px solid #2c7bfd;
    border-right: 1px solid #2c7bfd;
    border-bottom: 1px solid #2c7bfd;
    box-shadow: none;
  }

  &.pad-sm {
    padding: 8px 16px;
  }
}

@mixin round-splide-arrow() {
  .splide__arrow {
    background: url("data:image/svg+xml,%3Csvg width='49' height='49' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20.5' cy='20.5' r='20.5' transform='matrix(1 -1.74846e-07 -1.74846e-07 -1 4 45)' fill='%2321509E'/%3E%3Cpath d='M18.8578 24.6168C18.5741 24.9005 18.5741 25.3672 18.8578 25.6509L27.6435 34.4366C27.9272 34.7203 28.394 34.7203 28.6777 34.4366C28.9614 34.1529 28.9614 33.6862 28.6777 33.4025L20.409 25.1338L28.6777 16.8652C28.9614 16.5815 28.9614 16.1148 28.6777 15.8311C28.394 15.5473 27.9272 15.5473 27.6435 15.8311L18.8578 24.6168Z' fill='white'/%3E%3C/svg%3E%0A")
      no-repeat;
    width: 72px;
    height: 11px;
    box-sizing: content-box;
    padding: 20px 0;
    cursor: pointer;
  }
}

.lightbox-img {
  cursor: pointer;
}

.lightbox {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;

  &__backdrop {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    max-height: 90%;
    width: 100%;
    height: 100%;

    &__wrapper {
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; /* 16:9 aspect ratio */

      @media (min-width: 560px) {
        max-width: 100%;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  &__full-image {
    max-width: 100%;
    max-height: 100%;
    display: none; // Initially hidden, shown via JavaScript
  }
}

.pagination-component {
  flex: 1 1 100%;
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

  &-direction {
    width: 32px;
    height: 32px;
    border: 1px solid $color-htt-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 32px;
    border-radius: 3px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      border-color: $light-grey-border-color;
      color: $light-grey-border-color;

      .icon {
        color: $light-grey-border-color;
      }
    }

    &:first-child {
      transform: rotate(90deg);
    }

    &:last-child {
      transform: rotate(-90deg);
    }

    .icon {
      color: $color-htt-blue;
    }
  }

  &-list {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    max-width: 224px;
    width: 100%;
    justify-content: center;

    &-item {
      width: 32px;
      height: 32px;

      .pagination-component-button {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-htt-blue;
        border-radius: 3px;
        color: $color-htt-blue;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
          pointer-events: none;
        }

        &.active {
          color: #fff;
          background-color: $color-htt-blue;
        }
      }
    }
  }
}
