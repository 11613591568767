.th-courses {
  &__section {
    padding: 10px 0;

    h2 {
      position: relative;
      font-weight: 600;
      font-size: 24px;
      margin: 16px 0 32px;

      &::after {
        content: '';
        position: absolute;
        width: 60px;
        background: #2c7bfd;
        bottom: -8px;
        left: 0;
        height: 5px;
      }
    }
  }
}
