@mixin default-font($fsm: 14px, $fsd: 16px, $mw: 1024px) {
  font-family: var(--default-font);
  font-weight: 300;
  font-size: $fsm;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  @media screen and (min-width: $mw) {
    font-size: $fsd;
  }
}

.section-text {
  margin-top: 24px;
}

.section-heading-text-max-width {
  // sets max width to approx 70-80 chars - for <p> element section headings
  // Only for desktop, mobile we want full width and aligned left
  @media (min-width: 1024px) {
    max-width: 685px;
    margin: 0 auto;
    text-align: center;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left;
  }

  .text-md-center {
    text-align: center;
  }

  .text-md-right {
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left;
  }

  .text-lg-center {
    text-align: center;
  }

  .text-lg-right {
    text-align: right;
  }
}

// font size and weight

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-size-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.font-size-s {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.font-size-m {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

.font-size-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.font-size-l {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.font-size-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.break-words {
  overflow-wrap: break-word;
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.text-no-underline {
  text-decoration: none;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-branded {
  color: $color-htt-blue !important;
}

.text-light-grey {
  color: $light-grey-text-color !important;
}

.text-grey {
  color: #818b9a;
}

.text-red {
  color: #ff4555;
}

.text-green {
  color: #38c77e;
}

.disclaimer {
  color: #656569;
  font-size: 14px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
}
