.smarter-trader-section {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #454a51;

  @media (min-width: 1024px) {
    padding: 0 0 var(--basic-section-gap);
    border-bottom: none;
  }

  &.full-width {
    padding-bottom: 0;

    .smarter-trader {
      border-radius: 0;
    }
  }

  .htt-container {
    padding: 0;

    @media (min-width: 1024px) {
      padding: 0 var(--page-gutter);
    }
  }

  .htt-container {
    .smarter-trader {
      display: grid;
      grid-template-columns: 1fr;

      @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }

      .background-img-wrapper,
      .background-img {
        border-radius: 0;

        @media (min-width: 1024px) {
          border-radius: 0 var(--border-radius) var(--border-radius) 0;
        }
      }
    }
  }

  .smarter-trader {
    background: #202f45;
    padding: 0;
    position: relative;
    height: fit-content;

    .htt-container {
      display: grid;
      grid-template-columns: 1fr;

      @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 120px;
        align-items: center;
      }
    }

    @media (min-width: 1024px) {
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-gap: 120px;
      border-radius: var(--border-radius);
    }

    &__content {
      order: 2;
      padding: 32px;

      @media (min-width: 1024px) {
        order: 1;
      }
    }

    .background-img-wrapper {
      order: 1;
      flex: 1 1 50%;
      position: relative;

      @media (min-width: 1024px) {
        height: 100%;
        order: 2;
      }

      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(0deg, #202f45 0%, rgba(0, 0, 0, 0) 100%);

        @media (min-width: 1024px) {
          width: 100px;
          height: 100%;
          content: '';
          background: linear-gradient(90deg, #202f45 0%, rgba(0, 0, 0, 0) 100%);
        }
      }

      .background-img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        //@media (min-width: 1024px) {
        //  top: 0;
        //  right: 0;
        //  position: absolute;
        //  border-radius: 0 20px 20px 0;
        //  width: auto;
        //  max-width: 50%;
        //  max-height: 100%;
        //}
      }
    }
  }
}
