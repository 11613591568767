$most-popular: #a238d4;

.broker-comparison-section {
  .htt-container > .broker-comparison {
    padding: 0;

    @media (min-width: 576px) {
      padding: 0 var(--page-gutter);
    }
  }
}

.broker-comparison-embed {
  @media (max-width: 1464px) {
    .comparison-list {
      max-height: unset !important;
    }
  }
}

.broker-card {
  display: grid;
  grid-template-columns: 100%;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
  margin-bottom: 40px;
  width: 100%;

  @media (min-width: 1150px) {
    grid-template-rows: 87px auto 52px;
    margin-bottom: 76px;
  }

  &-header {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    position: relative;

    &__title {
      display: flex;
      align-items: center;
      padding: 27px 16px 16px;
      margin: 0;

      .number {
        font-size: 32px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0.125em;
        color: $color-htt-blue;

        &.popular {
          color: $most-popular;
        }
      }

      &__icon {
        margin-left: 8px;
        margin-right: 8px;
        max-height: 40px;
        width: auto !important;
        max-width: 100%;
      }
    }

    .separator {
      border-bottom: 1px solid #e2e8f1;
      border-top: none;
      margin: 0 16px;

      @media (min-width: 1150px) {
        margin: 0;
      }
    }

    .popular-tag {
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      height: 32px;
      background-color: $most-popular;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;

      svg {
        margin-right: 8px;
      }

      @media (min-width: 1150px) {
        position: absolute;
        width: 201px;
      }
    }
  }

  &-body {
    height: 100%;

    @media (min-width: 1150px) {
      display: flex;
    }

    &__left {
      //grid-gap: 24px;
      display: flex;
      flex: 1 1 100%;
      flex-wrap: wrap;
      padding: 16px;
      grid-gap: 24px;

      @media (min-width: 1150px) {
        flex: 0 1 30%;
      }
    }

    &__right {
      display: flex;
      flex: 1 1 70%;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 16px;
      position: relative;

      @media (min-width: 1150px) {
        flex: 1 1 30%;
      }

      &::before {
        @media (min-width: 1150px) {
          content: '';
          width: 1px;
          height: 100%;
          position: absolute;
          top: 1px;
          left: 0;
          background-color: #e2e8f1;
        }
      }
    }

    .deposit,
    .commision,
    .regulation,
    .spread,
    .markets-traded,
    .funding,
    .execution {
      .title {
        font-weight: 600;
      }

      .comparison-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-height: 260px;
        width: 100%;
        margin: 0 !important;

        @media (min-width: 576px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: 1024px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media (min-width: 1150px) {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
        }

        li {
          display: flex !important;
          align-items: center;
          margin-right: 20px;
          margin-bottom: 8px;
          line-height: 1.5;
        }
      }

      .check-simple-icon {
        color: $color-htt-blue;
        margin-right: 8px;
      }

      .cross-simple-icon {
        color: #ff5e5e;
        margin-right: 8px;
      }
    }

    .deposit {
      flex: 1 1 calc(50% - 12px);
      display: flex;
      flex-direction: column;
    }

    .commision {
      flex: 1 1 calc(50% - 12px);
      display: flex;
      flex-direction: column;
    }

    .regulation {
      flex: 1 1 calc(50% - 12px);
      display: flex;
      flex-direction: column;
    }

    .spread {
      flex: 1 1 calc(50% - 12px);
      display: flex;
      flex-direction: column;
    }

    .markets-traded {
      flex: 1 1 100%;

      @media (min-width: 1150px) {
        flex: 1 1 16%;
      }
    }

    .funding {
      flex: 1 1 100%;

      @media (min-width: 1150px) {
        flex: 1 1 33%;
      }
    }

    .execution {
      flex: 1 1 100%;

      @media (min-width: 1150px) {
        flex: 1 1 5%;
      }
    }
  }

  &-footer {
    .htt-btn {
      width: 100%;
      max-width: 100%;

      &.popular {
        background-color: $most-popular;
        border-color: $most-popular;

        &:hover {
          background-color: #fff;
          color: $most-popular;
          border-color: $most-popular;
        }
      }
    }
  }
}
