.page-bottom-banner-section {
  /*background: linear-gradient(
    90deg,
    #0b1550 30.91%,
    #0b1550 30.92%,
    #046195 65.34%,
    #056194 65.34%
  );*/
  background: radial-gradient(
    48.37% 95.56% at 139.1% -11.71%,
    #2c7bfd 0%,
    #06001e 99.02%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  padding: 32px 16px;
  position: relative;
  overflow: hidden;

  &.black-bg {
    background: linear-gradient(90deg, #0f0f0f 34.99%, #050b0b 60%);
    padding: 0;

    @media (min-width: 1024px) {
      padding: 32px 16px;
    }
  }

  &.faded-bg {
    background: linear-gradient(0deg, #0f0f0f 34.99%, #050b0b 60%);
    padding: 375px 0 0 0;

    @media (min-width: 1024px) {
      background: linear-gradient(90deg, #0f0f0f 34.99%, #050b0b 60%);
      padding: 32px 16px;
    }

    .bg-picture {
      display: flex;

      @media (min-width: 1024px) {
        display: unset;
      }
    }

    .desktop-bg {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      z-index: -3;
    }
  }

  .section-bg {
    z-index: 0;

    .page-bottom-banner-section-image-desktop {
      position: absolute;
      right: 0;
      height: 100%;
      max-width: fit-content;
      display: none;
      object-fit: cover;

      @media (min-width: 1024px) {
        display: block;
        width: 45%;
      }

      @media (min-width: 1200px) {
        width: 50%;
      }

      @media (min-width: 1440px) {
        display: none;
      }
    }
  }

  &-image {
    &.-right-align {
      display: none;

      @media (min-width: 1024px) {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 215px;
        margin-right: -80px;
        margin-bottom: -100px;
        margin-top: 40px;
      }

      @media (min-width: 1200px) {
        width: 256px;
      }
    }
  }

  .bottom-banner {
    .order-n1 {
      order: -1;

      @media (min-width: 1024px) {
        order: 0;
      }
    }

    h3 {
      font-size: 24px;
      margin-bottom: 8px;

      @media (min-width: 1024px) {
        font-size: 32px;
      }
    }

    p:not(.bottom-banner__iconlist__heading) {
      font-size: 14px;

      @media (min-width: 1024px) {
        max-width: 400px;
      }
    }

    &__iconlist {
      &__heading {
        font-size: 16px;
        margin: 16px 0;
      }

      &__item {
        &__text {
          font-size: 14px;
        }
      }
    }

    &__picture-middle {
      position: relative;
      margin: auto;
      width: 230px;
      opacity: 1;

      @media (min-width: 1024px) {
        position: absolute;
        z-index: 1;
        opacity: 0.25;
        width: 17%;
        left: 0;
        right: 0;
        margin: -5% 35%;
        display: block;
      }

      @media (min-width: 1200px) {
        width: 20%;
        margin: -5% 33%;
      }
    }

    &__iconlist__item {
      margin-bottom: 16px;
    }

    & > .flex {
      gap: 16px;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;
        gap: 0;
      }
    }

    .form-container {
      width: 100%;
      padding: 16px 12px;
      background: #1f1a35;
      border-radius: 4px;

      @media (min-width: 1024px) {
        max-width: 420px;
        padding: 24px;
        margin-left: -20px;
      }

      .htt-btn {
        padding: 0;
        height: 56px;
      }

      .text-small {
        display: block;
        font-size: 12px;
        margin: 12px 0;
      }

      .trustpilot-reviews svg {
        margin-left: 0;
        max-width: 164px;
      }

      .form-group {
        margin-bottom: 8px;
      }

      .htt-btn {
        text-transform: capitalize;
        letter-spacing: 0;
      }

      .form-input {
        border-radius: 4px;
      }
    }
  }

  .partnership {
    font-size: 14px;
    color: #b4b4b4;
    display: flex;
    align-items: center;
    gap: 3px;

    &.centered {
      justify-content: center;
    }
  }

  .basic-heading {
    font-size: 25px;
    font-weight: 700;
  }

  .htt-btn {
    &::after {
      -webkit-mask-size: 30px;
      mask-size: 30px;

      @include sprite-mask('arrow-right-flat');
    }
  }

  &-image-mobile {
    width: 100%;
    display: block;

    @media (min-width: 1024px) {
      display: none;
    }

    @media (min-width: 1440px) {
      max-width: 500px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      max-width: fit-content;
    }
  }

  &__numbered {
    margin-bottom: 24px;

    &__item {
      background: #365282;
      font-weight: 400;
      font-size: 15px;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 90px;
      gap: 8px;
      margin-bottom: 24px;
      position: relative;

      &:last-child {
        margin-bottom: 0;

        &::after {
          content: none;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 4px;
        position: absolute;
        left: 25px;
        height: 27px;
        bottom: -26px;
        background: #365282;
        z-index: 0;
      }

      &__number {
        background: transparent;
        font-size: 16px;
        font-weight: 700;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 36px;
      }

      &.first {
        background: #4581b2;

        .sidebar__numbered__item__number {
          background: #448ff7;
        }

        &::after {
          background: linear-gradient(180deg, #448ff7 0%, #365282 100%);
        }
      }
    }
  }

  .trustpilot-reviews {
    justify-content: center;

    @media (min-width: 1024px) {
      justify-content: flex-start;
    }
  }
}
