.th-reg {
  margin: 48px 0;
  container-type: inline-size;

  .-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 8px !important;

    &.-title-larger {
      font-size: 24px;
    }
  }

  .-subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 160%;
    margin-bottom: 8px !important;
  }

  .-wrapper {
    max-width: 688px;
    margin: 0 auto;
    justify-content: center;
  }

  .-disclaimer {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0 !important;

    a {
      color: inherit;
      font-weight: 500;
      text-decoration: none;
    }
  }

  .-centered-img {
    margin: 0 auto;
  }

  .-btn {
    text-transform: uppercase;
    //letter-spacing: initial;
    font-size: 16px;
    padding: 14px 32px;
    width: unset;
  }

  .-btn svg {
    fill: currentColor;
    margin-left: 8px;
  }

  .-form-email {
    .-container {
      display: inline-grid;
      max-width: 428px;
      grid-gap: 0;
      grid-template-columns: 2fr 1fr;
    }

    .form-input {
      background: $bg-color;
      font-size: 14px;
      border: 1px solid $light-grey-border-color;
      color: $dark-text-color;
    }

    .-btn {
      font-size: 14px;
    }

    @media (max-width: 425px) {
      .form-input {
        text-align: center;
      }

      .-container {
        width: 100%;
        grid-gap: 12px;
        grid-template-columns: 1fr;
      }
    }
  }

  .-icon-arrow::after {
    content: url(/static/icon-svg/arrow.svg);
    padding-left: 13px;
  }

  &__blog {
    background: rgba(44, 123, 253, 0.05);
    border-style: solid;
    border-color: rgba(44, 123, 253, 0.5);
    border-width: 0.6px 0;
    padding: 52px 24px;
    text-align: left;

    @media (min-width: 768px) {
      text-align: center;
    }

    &.blog-1 {
      padding: 0;
      border: none;
      margin: 8px 0 48px;

      .blog-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        @media (min-width: 768px) {
          flex-wrap: nowrap;
        }

        .-centered-img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    &-tag {
      color: rgba(15, 15, 15, 0.5) !important;
    }

    &-separator {
      border: none;
      margin: 8px 0;
      border-top: 0.6px solid rgba(44, 123, 253, 0.5);
    }
  }

  .blog-1-content {
    padding: 16px;
    flex: 1 1 auto;
    align-self: center;

    @media (min-width: 768px) {
      padding: 32px 32px 32px 0;
    }

    &-lower {
      border-top: 1px solid #cddaf0;
      border-bottom: 1px solid #cddaf0;
      text-align: left;
      margin-bottom: 24px;
      margin-top: 24px;
      padding-top: 16px;

      p {
        margin-bottom: 16px;
      }
    }

    .list {
      list-style-type: none;
      padding-left: 0;
      margin: 0 0 16px;

      .list-item {
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }
  }

  &__blue {
    background: linear-gradient(
      145.98deg,
      rgba(24, 63, 128, 0.9) 0.05%,
      rgba(44, 123, 253, 0.9) 79.32%
    );
    padding: 52px 24px;
    text-align: center;
    color: #fff;
  }

  //&__dark {
  //  background: #0f0f0f;
  //  color: #fff;
  //  padding: 48px 24px;
  //  text-align: center;
  //
  //  .-title.-title-larger {
  //    font-family: var(--heading-font);
  //    font-size: 40px;
  //    line-height: 40px;
  //    margin-top: 0;
  //  }
  //
  //  .cta-signup {
  //    display: grid;
  //    grid-template-columns: 1fr;
  //    max-width: 455px;
  //    margin: 16px auto 0;
  //    grid-gap: 16px;
  //
  //    @media (min-width: 576px) {
  //      grid-template-columns: 1fr 1fr;
  //      grid-gap: 0;
  //    }
  //  }
  //
  //  .form-input {
  //    background: #fff;
  //    height: 50px;
  //    padding: 0 16px;
  //    box-sizing: border-box;
  //  }
  //
  //  .cta-btn {
  //    background: #2c7bfd;
  //    color: #fff;
  //    height: 50px;
  //    box-sizing: border-box;
  //  }
  //
  //  .cta-signup-success {
  //    text-align: center;
  //    padding: 20px 0;
  //
  //    &.hidden {
  //      display: none;
  //    }
  //  }
  //
  //  .cta-signup-container {
  //    &.hidden {
  //      display: none;
  //    }
  //  }
  //}

  &__webinar-email {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(106.33deg, #2c7bfd 5.29%, #2c7bfd 5.3%, #2cbefd 75.06%);
    color: #ffffff;
    text-align: center;
    padding: 48px 8px;

    .-form-email {
      margin-bottom: 12px;
    }

    .-subtitle {
      margin-bottom: 32px !important;
    }
  }

  &__journal-email {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      linear-gradient(106.33deg, #2c7bfd 5.29%, #2c7bfd 5.3%, #2cbefd 75.06%);
    color: #ffffff;
    padding: 48px 6.7%;
    display: flex;

    .-form-email {
      margin-bottom: 12px;
    }

    .-title {
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      margin-bottom: 4px !important;

      span {
        font-weight: 700;
      }
    }

    .-subtitle {
      margin-bottom: 12px !important;
    }

    .-content {
      flex: 1 1 auto;
    }

    .-side {
      display: flex;
      align-items: center;

      img {
        max-width: 174px;
        position: relative;
        right: -32px;
      }

      @media (max-width: 622px) {
        display: none;
      }
    }
  }

  &__chart-email {
    background: linear-gradient(0deg, #191c21, #191c21), rgba(0, 0, 0, 0.4);
    color: #ffffff;
    width: 100%;

    > div {
      max-width: 1200px;
      margin: 0 auto;
      padding: 80px 6.7%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    .-form-email {
      margin-bottom: 12px;
    }

    .-title {
      margin-bottom: 12px !important;
    }

    .-content {
      padding-right: 8px;
    }

    .-btn {
      padding: 12px 16px;
    }

    @media (max-width: 844px) {
      > div {
        flex-direction: column;
        align-items: flex-start;
      }

      .-content {
        padding-right: 0px;
        padding-bottom: 32px;
      }
    }
  }

  .countdown-blog-stream {
    @include countdownstyle(transparent, #0f0f0f, 0 auto, transparent);

    @media (min-width: 1024px) {
      margin: 0 auto 16px 0;
    }
  }

  .countdown-blog-promo {
    @include countdownstyle(transparent, #fff, 0 auto, transparent);

    @media (min-width: 1024px) {
      margin: 0 auto 16px 0;
    }
  }
}
