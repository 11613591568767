// header and nav

$mobile-menu-z-index: 200;

.htt-header {
  @extend .black-on-white;

  --menu-text-color: #2c3d55;
  --menu-text-color-active: #2c7bfd;
  --mobile-menu-bg-color: #fff;
  --mobile-menu-border-color: #e4e5e6;
  --submenu-link-hover-color: #f5f8ff;
  --submenu-icon-color: #2c7bfd;
  --submenu-icon-bg-color: #d6e5ff;
  --submenu-link-hover-arrow-color: #cddaf0;
  --header-spot-bg-color: var(--submenu-link-hover-color);

  width: 100%;
  position: fixed;
  height: var(--header-height);
  top: 0;
  z-index: $mobile-menu-z-index;
  transition: transform 0.35s ease-out, background-color 0.25s linear;

  &.temp-dim {
    @media (min-width: 1024px) {
      opacity: 0.5;
    }
  }

  &.disable-sticky {
    // position: static;
    position: absolute;
  }

  &.mobile-open {
    background: var(--mobile-menu-bg-color) !important;
    box-shadow: none !important;
    border-bottom: 1px solid var(--mobile-menu-border-color);

    .mobile-menu-button {
      .bar {
        &:nth-child(1) {
          transform: rotate(-45deg) translateY(-3px);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(45deg) translateY(3px);
        }
      }
    }

    .mobile-menu {
      visibility: visible;
      opacity: 1;

      .mobile-menu__item {
        opacity: 1;
        transform: translateY(0);

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            transition-delay: #{0.07 * $i + 0.2}s;
          }
        }
      }
    }
  }

  &.transparent,
  &.dark {
    --menu-text-color: #fff;
    --menu-text-color-active: #609dff;
    --mobile-menu-bg-color: #0f0f0f;
    --mobile-menu-border-color: rgb(228 229 230 / 20%);
    --submenu-link-hover-color: #3e4151;
    --submenu-icon-color: #fff;
    --submenu-icon-bg-color: #2f2f2f;
    --submenu-link-hover-arrow-color: #fff;
    --link-mask-color: #fff;

    .desktop-menu {
      .desktop-submenu {
        --submenu-border-color: #3c4249;
        --submenu-bg-color: #191c21;

        box-shadow: 0px 0px 15px -5px rgb(0 0 0 / 50%);
      }

      &__link {
        &:hover {
          color: var(--menu-text-color);
        }
      }
    }

    .header-spot-support-bg {
      @include sprite(
        'nav-ad-support',
        (
          fill: #606369,
        )
      );
    }

    .htt-logo {
      @include sprite(
        'howtotrade-logo',
        (
          text-fill: $color-white,
        )
      );
    }
  }

  &.transparent {
    .desktop-menu {
      &__link {
        opacity: 0.85;

        &:hover {
          opacity: 1;
        }
      }

      &__button {
        opacity: 1;
      }
    }
  }

  &.headroom--pinned {
    transform: translateY(0%);
  }

  &.headroom--unpinned {
    transform: translateY(-100%);

    .desktop-submenu {
      display: none;
    }
  }

  &.headroom--not-top {
    // background: $bg-color;
    background: rgb($color-white, 0.9);
    backdrop-filter: blur(8px);
    --header-height: 64px;
    position: fixed;
    border-bottom: 1px solid $light-grey-border-color;

    @supports not (backdrop-filter: blur(8px)) {
      background: $bg-color;
    }

    .desktop-menu {
      color: $dark-text-color;

      &__button {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .mobile-menu-button {
      .bar {
        background: var(--menu-text-color);
      }
    }
  }

  .htt-logo {
    @include sprite(
      'howtotrade-logo',
      (
        text-fill: $color-onyx,
      )
    );
    width: 156px;
    height: 28px;
    flex-shrink: 0;
  }

  .header-spot {
    padding: 20px 10px 60px;
    display: flex;
    flex-direction: column;

    &__text {
      font-size: 12px;
    }

    .auth-link {
      color: var(--menu-text-color-active);
      text-align: center;
      text-transform: uppercase;
      padding: 12px 0;
      border: 1px solid $color-htt-blue;
      border-radius: var(--border-radius);
      margin-bottom: 8px;
      text-decoration: none;
      font-weight: 500;
    }

    .htt-btn {
      padding: 16px;
    }

    .link-mask-icon {
      align-self: flex-start;
    }

    .vimeo-player {
      &__inner {
        &::after {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}

.mobile-nav-wrap {
  .htt-logo,
  .mobile-menu-button {
    position: relative;
    z-index: $mobile-menu-z-index + 1;
  }
}

.desktop-nav-wrap {
  display: none;
}

@media screen and (min-width: 1024px) {
  .mobile-nav-wrap {
    display: none;
  }

  .desktop-nav-wrap {
    display: flex;
  }
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
}

.mobile-menu {
  padding: 20px var(--page-gutter) 40px;
  position: absolute;
  top: var(--header-height);
  left: 0;
  right: 0;
  color: var(--menu-text-color);
  background: var(--mobile-menu-bg-color);
  height: calc(100vh - var(--header-height));
  height: calc(100dvh - var(--header-height));
  font-size: 16px;
  z-index: $mobile-menu-z-index;
  visibility: hidden;
  opacity: 0;
  transition: all 0.375s;
  overscroll-behavior: contain;
  overflow-y: auto;

  &__list,
  &__item {
    list-style: none;
    padding: 0;
  }

  &__item {
    margin: 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: transform 0.5s ease, opacity 0.6s ease;
  }

  .mobile-menu-expander,
  &__link {
    padding: 12px 0;
    line-height: 1.3;
    letter-spacing: 0;
  }

  &__link {
    display: block;
    text-decoration: none;
    font-weight: 400;
    color: inherit;

    &:hover {
      color: inherit;
    }

    &.pro {
      &::after {
        content: 'PRO';
        background: $color-htt-blue;
        padding: 2px 4px;
        border-radius: 4px;
        margin-left: 5px;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
      }
    }

    &.new {
      &::after {
        content: 'NEW';
        background: $color-htt-blue;
        padding: 2px 4px;
        border-radius: 4px;
        margin-left: 5px;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
      }
    }

    &.hidden-link {
      display: none;
    }
  }

  .header-spot {
    .htt-btn {
      max-width: unset;
    }
  }

  .mobile-submenu {
    padding: 0;
  }

  .mobile-submenu-link {
    padding: 12px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    color: inherit;
    font-weight: 300;
    text-decoration: none;

    &.has-icon {
      position: relative;
    }

    .submenu-icon {
      padding: 6px;
      background: var(--submenu-icon-bg-color);
      border-radius: 2px;
      color: var(--submenu-icon-color);
    }
  }
}

.desktop-menu {
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: var(--menu-text-color);
  gap: 32px;

  @media (min-width: 1200px) {
    margin-left: 5%; // 120px
  }

  &__item,
  .desktop-submenu-list {
    list-style: none;
    padding: 0;
  }

  &__item {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  &__link {
    font-weight: 500;
    color: var(--menu-text-color);
    flex-shrink: 0;
    text-decoration: none;
    transition: opacity 0.25s ease;
    cursor: pointer;
    position: relative;

    &:hover,
    &.active {
      font-weight: 600;
    }

    &::before {
      content: attr(data-title);
      display: block;
      font-weight: 600;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &.pro {
      &::after {
        content: 'PRO';
        background: $color-htt-blue;
        padding: 2px 4px;
        border-radius: 4px;
        position: relative;
        right: -4px;
        top: -10px;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
      }
    }

    &.new {
      &::after {
        content: 'NEW';
        background: #2c7bfd;
        padding: 2px 4px;
        border-radius: 4px;
        margin-left: 5px;
        position: relative;
        right: -4px;
        top: -10px;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
      }
    }

    &.hidden-link {
      display: none;
    }

    &.auth-link {
      font-weight: 300;
      transition: 0.3s font-weight;

      &:hover {
        font-weight: 600;
      }
    }
  }

  &__button {
    @extend .htt-btn;
    opacity: 1;
  }

  .submenu-parent {
    padding: 26px 0;
    font-weight: 400;

    .desktop-menu__link {
      cursor: default;
    }

    .desktop-menu__link__icon {
      position: relative;
      left: 5px;
      top: 3px;
      transition: 0.3s;
    }
  }

  .submenu-parent:hover > .desktop-submenu,
  .submenu-parent:focus-within > .desktop-submenu,
  .desktop-submenu:hover,
  .desktop-submenu:focus {
    visibility: visible;
    opacity: 1;
    display: flex;
    width: 100%;
  }

  .submenu-parent:hover,
  .submenu-parent:focus-within {
    .desktop-menu__link {
      opacity: 1;
      font-weight: 500;
      color: $color-htt-blue;
    }

    .desktop-menu__link__icon {
      transform: rotate(180deg);
      color: $color-htt-blue;
    }
  }

  .desktop-submenu {
    --submenu-border-color: #d5e5ff;
    --submenu-bg-color: #fff;

    position: fixed;
    top: calc(var(--sticky-height) - 1px);
    left: 0;
    z-index: 5;
    border-radius: 0;
    color: var(--menu-text-color);
    background: var(--submenu-bg-color);
    border-top: 1px solid $light-grey-border-color;
    border-bottom: 1px solid $light-grey-border-color;
    box-shadow: 0px 20px 20px 0px rgba(44, 61, 85, 0.25);
    font-size: 14px;
    text-align: left;
    display: none;

    &.show {
      display: flex;
    }

    // popup arrow

    $arrow-size: 14px;

    //&::before,
    //&::after {
    //  //content: '';
    //  position: absolute;
    //  border-left: $arrow-size solid transparent;
    //  border-right: $arrow-size solid transparent;
    //  bottom: 100%;
    //  left: 50%;
    //  margin-left: -$arrow-size;
    //  // transition: all 0.5s ease;
    //}

    &::before {
      //border-bottom: $arrow-size solid var(--submenu-border-color);
    }

    &::after {
      border-bottom: $arrow-size solid var(--submenu-bg-color);
      margin-bottom: -1px;
      z-index: 1;
    }
  }

  .desktop-submenu-list {
    padding: 16px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    &__item {
      flex: 0 0 calc(33% - 24px);
    }
  }

  .header-spot-support {
    width: 260px;

    .header-spot-support-bg {
      width: 144px;
      height: 100px;
      align-self: center;

      @include sprite('nav-ad-support');
    }
  }

  .header-spot-webinar {
    width: 270px;

    .link-mask-icon {
      &::before {
        @include sprite-mask('play-strict');
      }
    }
  }

  .header-spot-comparison {
    width: 270px;

    .link-mask-icon {
      &::before {
        @include sprite-mask('play-strict');
      }
    }
  }

  .header-spot-trailer {
    width: 324px;

    .link-mask-icon {
      &::before {
        @include sprite-mask('play-strict');
      }
    }
  }

  .desktop-submenu-link {
    padding: 14px;
    display: flex;
    flex-direction: column;
    color: var(--menu-text-color);
    text-decoration: none;

    &:hover {
      background: var(--submenu-link-hover-color);
      border-radius: var(--border-radius);
      color: var(--menu-text-color);
      text-decoration: none;
    }

    &__title {
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: capitalize;
    }

    &__text {
      font-size: 16px;
      margin-top: 8px;
      font-weight: 400;
    }

    .submenu-icon {
      padding: 6px;
      background: var(--submenu-icon-bg-color);
      border-radius: 2px;
      color: var(--submenu-icon-color);
    }
  }
}

.auth-links {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;

  .desktop-menu__item:first-child {
    &::after {
      content: '';
      width: 1px;
      height: 26px;
      position: absolute;
      left: calc(100% + 8px);
      background-color: var(--menu-text-color);
    }
  }
}

.mobile-menu-button {
  width: 32px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  background: none;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  .bar {
    width: 32px;
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--menu-text-color);
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transform-origin: right;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 9px;
      width: 23px;
      transform: translateX(9px);
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}
