/* stylelint-disable selector-max-type */
@import 'resets';
@import '~svg-spritemap-webpack-plugin/sprites';
@import 'root';
@import 'fonts';
@import 'colors';
@import 'utils';
@import 'typography';
@import 'framework';
@import 'sections/header-navigation';
@import 'sections/footer';
@import 'forms';
@import 'blocks/sign';
@import '~glightbox/dist/css/glightbox.css';
@import 'blocks/brokers-comparison';
@import 'wp/page-bottom-banner';
@import 'exit-popup';
@import 'blocks/modal-newsletter';
@import 'sections/sm-partnership-banner';
//@import 'blocks/debug-panel';

body {
  -webkit-font-smoothing: antialiased;
  color: $dark-text-color;
  font-family: var(--default-font);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--default-font);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.35;
}

.htt-base {
  @include default-font;

  background: #fff;
  color: $dark-text-color;
  overflow: unset;

  h1,
  h2,
  h3,
  p,
  span,
  li,
  p {
    font-family: var(--default-font);
    color: inherit;
  }
}

.basic-heading {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  font-family: var(--heading-font);
  text-transform: capitalize;

  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  }

  &.full-width-line {
    position: relative;
    display: inline-block;
    margin-bottom: 6px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -7px;
      height: 3px;
      background-color: $color-htt-blue;
      z-index: 2;
    }
  }
}

#error {
  padding: 230px 15px 130px;
  text-align: center;
}

body a {
  font-family: inherit;
  color: $link-color;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: $link-color;
    text-decoration: none;
  }
}

.background-blur {
  background: rgba(15, 15, 15, 0.6);
  backdrop-filter: blur(5px);
}

.screen-section,
.basic-section,
.extended-section {
  position: relative;
}

.section-bg {
  overflow: hidden;
  top: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-size: cover;
  opacity: 1;
  transition: opacity 0.25s ease;
  overflow: hidden;

  .bg-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: -3;
  }
}

@import 'wp/popup';
@import 'wp/post';
@import 'wp/section';
@import 'wp/course';
@import 'wp/courses';
@import 'wp/blog';
@import 'wp/page';
@import 'wp/reg-sections';
@import 'wp/banner-sections';

.htt-header,
.htt-footer,
.cookie-consent {
  @include default-font;

  // resets
  &,
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  button {
    outline: 0;
    font-style: inherit;
    font-family: inherit;
    // font-size: 100%;
    vertical-align: baseline;
    // letter-spacing: initial;
  }

  img {
    width: 100%;
    height: auto;
  }

  .footer-iso-certified {
    width: 80px;
  }

  ul,
  li {
    list-style: none;
  }

  .link-mask-icon,
  .mobile-menu-button {
    padding: 0;
    border: 0;
    background: none;
  }
}

.htt-header,
.htt-footer {
  a {
    // color: inherit;
    cursor: pointer;

    &:hover {
      color: $color-htt-blue;
      text-decoration: none;
    }
  }
}

.cookie-consent {
  button {
    font-size: 14px;
  }
}

// Table of contents and blockquotes styling
.htt-post {
  .table-of-contents {
    background: #eaf2ff;
    padding: 32px 16px;

    #toc-title {
      padding: 16px;
      background-color: #fff;
      border: 1px solid rgba(44, 123, 253, 0.25);
      font-size: 18px;
      font-weight: 500;
      line-height: 29px;
      margin-bottom: 24px;

      img {
        vertical-align: middle;
        margin-right: 12px;
      }
    }

    ul {
      list-style-type: decimal;
      padding-left: 30px;
      margin: 0;

      li {
        margin-bottom: 16px;
        padding-left: 8px;

        &::marker {
          font-weight: 300;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          text-decoration: underline;
          color: #0f0f0f;
          font-weight: 300;
        }
      }
    }
  }

  .content-disclaimer {
    margin-top: 3em;

    &__text {
      font-size: 0.7rem;
      color: #606060;
    }
  }

  blockquote,
  .wp-block-quote {
    background-color: #f0f3f6;
    border-left: 2px solid #e2e7ed;
    padding: 1.5em 1.8em;
    margin: 1.2em 0;
  }
}

.newsletter {
  .form-input[type='email'] {
    color: #fff;
    border: 1px solid #cfcfcf;

    &::placeholder {
      color: #fff;
    }
  }
}
