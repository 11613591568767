.th-course {
  &-intro {
    padding-top: 132px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 47px;
  }

  &-intro__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 16px;
    min-height: 170px;
    border-radius: 4px;
    border: solid 1px #e8e8e8;
    background-color: #ffffff;

    > div {
      padding: 16px;
      flex: 1 0 0;
    }

    .title {
      display: flex;
      min-width: 320px;

      .img {
        width: 95px;
        height: 95px;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50% !important;
        flex: 0 0 auto;
        margin-right: 16px;
      }

      span {
        display: block;
        font-size: 12px;
        font-weight: normal;
        line-height: 1.75;
        letter-spacing: 0.09px;
        color: #8d93ae;
      }

      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: normal;
        color: #1b295e;
        margin: 0;
      }
    }

    .progress {
      border: 1px solid #e8e8e8;
      border-top: 0;
      border-bottom: 0;
      flex: 1 1 0;
    }

    .progress > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .progress p {
      margin: 0;
    }

    .progress .visual {
      width: 68px;
      height: 68px;
      flex: 0 0 auto;
    }

    .progress .mid {
      flex: 0 0 24px;
    }

    .progress .per {
      margin: 0 0 4px;
      font-size: 32px;
      font-weight: 600;
      line-height: 0.88;
      letter-spacing: normal;
      color: #1b295e;
    }

    .progress .status {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.75;
      letter-spacing: 0.09px;
      color: #8d93ae;
    }

    .action {
      display: flex;
      align-items: center;
    }

    .action span {
      font-size: 12px;
      font-weight: 600;
      color: var(--th-light-blue);
      text-transform: uppercase;
    }

    .action .mid {
      flex: 1;
      min-width: 16px;
    }

    .action a {
      display: block;
    }

    .action .th-button-primary {
      padding: 8px 26px 8px 27px;
      font-size: 14px;
    }

    .action .th-button-secondary {
      padding: 8px 26px 8px 27px;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  &-content .ld-course-status,
  &-content .ld-alert-certificate {
    display: none !important;
  }

  &-content {
    padding-bottom: 60px;
  }

  &-content__sidebar__main {
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15),
      0 0 0 1px rgba(63, 63, 68, 0.05);
    background-color: #ffffff;
  }

  &-content__sidebar__main .course-intro__preview {
    border: 0;
    overflow: hidden;
  }

  &-content__sidebar__main .info {
    padding: 16px;
  }

  &-content__sidebar__main .title {
    display: flex;
    align-items: center;
  }

  &-content__sidebar__main .title > p {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    color: #1b295e;
    flex: 1 1 auto;
  }

  &-content__sidebar__main .title .per {
    width: 65px;
    height: 65px;
    flex: 0 0 auto;
    display: none;
  }

  &-content__sidebar__progress_label {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b295e !important;
  }

  &-content__sidebar__main .description {
    margin: 16px 0 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $sm-dark;
  }

  &-content__sidebar__main .th-button-primary {
    margin: 16px 0 0;
    display: block;
    width: 100%;
    font-size: 14px;
  }

  &-content__sidebar__main .info .status {
    margin: 13px 0 0;
    display: none;
    text-align: center;
  }

  &-content__sidebar__share {
    margin: 24px 0;
  }

  &-content__sidebar__banner {
    margin: 32px 0;
    text-align: center;
  }

  &-content__content__banner {
    display: none;
    margin: 32px 0 0;
    text-align: center;
  }

  @media (max-width: 767px) {
    &-content__row {
      flex-direction: column-reverse;
    }

    &-intro .container {
      display: none;
    }

    &-content__sidebar {
      margin-top: -70px;
      margin-bottom: 32px;
      overflow: hidden;
    }

    &-content__sidebar__main .description {
      display: none;
    }

    &-content__sidebar__main .info .status {
      display: block;
    }

    &-content__sidebar__main .title .per {
      display: block;
      margin: 0 0 0 16px;
    }

    &-content__sidebar__banner {
      display: none;
    }

    &-content__content__banner {
      display: block;
    }
  }

  &-popup {
    visibility: hidden;

    img {
      margin: 28px 0 33px;
    }

    &__popup .title span {
      color: $color-htt-blue;
    }

    &__popup .dismiss {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #1b295e;
    }

    &__popup .th-button-primary {
      display: inline-block;
      margin: 40px 0 8px 0;
      font-size: 17px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: center;
      max-width: 400px;
      text-transform: initial;
    }
  }

  &-card {
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.08) !important;
    overflow: hidden;
    transform: none !important;
    margin-bottom: 4%;

    &__article {
      padding: 0;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__head {
      background-color: green;
      padding: 27px;
      text-align: center;
      color: #ffffff;
    }

    &__article.forex &__head {
      background: linear-gradient(103.53deg, #22c076 25.95%, #65eeae 99.12%);
    }

    &__article.stocks &__head {
      background: linear-gradient(104.71deg, #25136c 0%, #8572cf 100%);
    }

    &__head--title {
      display: flex;
      min-height: 65px;
      align-items: center;
      justify-content: center;
    }

    &__head h3 {
      font-size: 24px;
      line-height: 130%;
      color: #ffffff;
      margin: 4px 0;
    }

    &__info {
      display: flex;
      flex-wrap: wrap;
      margin: 8px 16px;
      font-size: 14px;
      line-height: 140%;
      color: #132132;
    }

    &__info > div {
      flex: 1 1 50%;
      min-width: 120px;
      padding: 8px 4px;
    }

    &__info img {
      float: left;
      margin-right: 4px;
    }

    &__body {
      flex-grow: 1;
      font-size: 16px;
      line-height: 150%;
      color: #323232;
      padding: 8px 16px;
    }

    &__footer {
      padding: 16px;
    }

    &__footer a {
      background: #2bcc7a;
      font-weight: 700;
      color: #ffffff;
      padding: 13px;
      width: 100%;
      display: block;
      text-align: center;
      transition: all 0.15s ease;
      text-decoration: none;
    }

    &__article.forex &__footer a {
      background: #2bcc7a;
    }

    &__article.stocks &__footer a {
      background: #25136c;
    }

    &__footer a:hover {
      color: #ffffff;
      transform: scale(1.02);
    }

    &__ribbon {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px 16px;
      background-color: #2bcc7a;
      box-shadow: 0px 4px 9px rgb(43 204 122 / 15%);
      color: #ffffff;
      font-weight: 600;
      font-size: 14px;
    }

    &__ribbon.resume {
      background-color: #febf30;
    }

    &__ribbon.enrolled {
      background-color: #428bca;
    }

    &__ribbon.completed {
      background-color: #2bcc7a;
    }
  }
}
