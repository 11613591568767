@import '../blocks/wp-sidebar-banner';
@import '../sections/smarter-trader';

@media (min-width: 1024px) {
  .htt-header.headroom--pinned + #content .htt-post-sidebar {
    padding-top: calc(var(--header-height) + 48px);
  }
}

.htt-post {
  @include default-font(16px, 18px, 720px);

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 668px 356px;
    justify-content: center;
  }

  &-sidebar {
    display: none;
    padding-top: var(--header-height);
    padding-bottom: 96px;
    position: relative;

    @media (min-width: 1024px) {
      display: block;
      padding-right: 16px;
    }

    @media (min-width: 1200px) {
      padding-right: 0;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.3;
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.6;

    span {
      color: inherit !important;
    }
  }

  h3 {
    font-size: 22px;
    font-weight: 700;
  }

  h4 {
    font-size: 20px;
    font-weight: 700;
  }

  @media screen and (min-width: 720px) {
    h1 {
      font-size: 38px;
    }

    h2 {
      font-size: 32px;
    }

    h3 {
      font-size: 26px;
    }

    h3 {
      font-size: 24px;
    }
  }

  ol {
    li::marker {
      font-weight: 700;
    }
  }

  &-container {
    @include container(720px, 16px, 720px);
    padding-top: var(--header-height);
    padding-bottom: 96px;
  }

  &-header {
    position: relative;
    min-height: 235px;

    .breadcrumbs {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 300;
      color: $color-htt-blue;
      overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;

      span {
        color: $dark-text-color;
        font-weight: 300;
        text-decoration: none;
      }

      a {
        text-decoration: none;
      }
    }

    h1 {
      margin-bottom: 16px;
    }

    .meta {
      font-size: 14px;
      padding-bottom: 8px;
      border-bottom: 4px solid $color-htt-blue;
      display: inline-flex;
      align-items: center;
      margin-bottom: 28px;
      gap: 5px;
      width: 100%;

      li {
        color: rgba(15, 15, 15, 0.75);

        > span {
          font-size: 14px;
          font-weight: 600;
        }

        > time {
          white-space: nowrap;
        }
      }

      .date-published {
        &.hidden {
          display: none;
        }
      }

      @media screen and (min-width: 720px) {
        font-size: 16px;
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
    }

    .htt-post-authors {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      padding: 5px 0;

      img {
        border-radius: 50%;
        margin-right: 5px;
      }

      .written {
        padding-right: 8px;
        display: flex;
        align-items: center;
      }

      .reviewed {
        border-left: 1px solid #0f0f0f;
        padding-left: 8px;
        display: flex;
        align-items: center;
      }

      .font-weight-700 {
        text-decoration: none;
        color: #0f0f0f;
      }
    }

    .social {
      display: flex;
      align-items: center;
      min-height: 48px;
      margin-bottom: 24px;

      a,
      p {
        margin: 0;
        text-align: center;
      }

      p {
        font-size: 14px;
        margin: 6px;
      }

      a {
        flex: 0 0 48px;
        line-height: 0;
        max-height: 52px;
        width: auto;

        img {
          width: auto;
          max-height: 52px;
        }
      }

      @media screen and (min-width: 720px) {
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: 720px) {
      padding-right: 64px;
    }
  }

  &-content {
    .social {
      text-align: center;
      padding: 24px 0;

      > p {
        margin-bottom: 12px;
        font-weight: 400;
      }

      > div {
        display: flex;
        align-items: center;
        min-height: 48px;
        justify-content: center;

        a,
        p {
          margin: 0;
          text-align: center;
        }

        p {
          font-size: 14px;
          margin: 6px;
        }

        a {
          flex: 0 0 48px;
          line-height: 0;
          margin: 0 6px;

          img {
            margin: 6px;
          }
        }

        .divider {
          flex: 1 1 auto;
          height: 1px;
          background-color: transparent;

          &:first-child {
            margin-right: -4px;
          }

          &:last-child {
            margin-left: -4px;
          }
        }
      }

      @media screen and (min-width: 720px) {
        > div {
          a {
            margin: 0 16px;
          }

          .divider {
            background-color: currentColor;
          }
        }
      }
    }
  }
}

.related-posts-title {
  font-size: 22px;
  font-weight: 700;

  @media screen and (min-width: 720px) {
    font-size: 26px;
  }
}

.related-posts-container {
  margin-bottom: 96px;
}

.related-post-card {
  border: 1px solid $light-grey-border-color;
  color: $dark-text-color;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  flex: 1 1 100%;
  border-radius: var(--border-radius);
  align-self: stretch;

  @media (min-width: 768px) {
    flex: 1 1 calc(50% - 24px);
  }

  @media (min-width: 1200px) {
    flex: 1 1 calc(33% - 24px);
    max-width: 350px;
  }

  &:not(.no-hover) {
    &:hover {
      box-shadow: 0 3px 20px #cfcfcf;
      transform: translateY(-3px);
      transition: 0.3s;
    }
  }

  &.hidden {
    display: none;
  }

  &__img {
    width: 100%;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  &__date {
    color: $faded-text-color-dark;
    padding: 24px 16px 8px;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;

    .category-tag {
      border-radius: 3px;
      background: #2c3d55;
      padding: 6px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }
  }

  &__title {
    font-family: var(--heading-font);
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    padding: 0 16px 8px;
    flex: 1 1 auto;
  }

  &__desc {
    padding: 0 16px 8px;
  }

  &__more {
    color: $color-htt-blue;
    padding: 0 16px 24px;
    font-weight: 600;
  }
}
