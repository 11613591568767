.htt-post,
.learndash {
  .th-banner-chart-1 {
    display: flex;
    border-left: 8px solid $color-htt-blue;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    padding: 16px;
    align-items: center;
    gap: 16px;
    flex-direction: column;

    @media (min-width: 576px) {
      flex-direction: row;
    }

    &__img {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      border: 2px solid #03e270;
      display: none;

      @media (min-width: 576px) {
        display: block;
      }
    }

    &__heading {
      font-size: 18px;
      line-height: 1.4;
      font-weight: 600;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        margin-bottom: 8px;
      }
    }

    &__text {
      margin-bottom: 0;
    }

    .htt-btn {
      flex: 0 0 52px;

      @media (min-width: 576px) {
        flex: 0 0 175px;
      }
    }
  }

  .th-banner-chart-2 {
    padding: 0;
    border: none;
    margin: 8px 0 48px;

    &__heading {
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &__text {
      font-size: 14px;
      padding-bottom: 8px;
      border-bottom: 1px solid #cddaf0;
    }

    .chart-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      align-items: flex-end;

      @media (min-width: 768px) {
        flex-wrap: nowrap;
      }
    }

    &__content {
      flex: 1 1 100%;

      @media (min-width: 768px) {
        flex: 0 1 50%;
      }
    }

    &__listheading {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    &__list {
      list-style-type: none;
      padding-left: 0;
      margin: 0 0 16px;

      &-item {
        font-size: 14px;
        display: flex;
        align-items: center;

        svg {
          width: 14px;
          height: 14px;
          min-width: 14px;
          min-height: 14px;
          flex: 0 0 14px;
          align-self: flex-start;
          margin-top: 3px;
          margin-right: 5px;
        }
      }
    }

    &__img {
      flex: 1 1 100%;
      width: 100%;
      max-width: 350px;
      margin: 0 auto;

      @media (min-width: 768px) {
        flex: 1 1 50%;
        max-width: 50%;
        margin: 0;
      }
    }

    .htt-btn {
      &::after {
        -webkit-mask-size: 30px;
        mask-size: 30px;

        @include sprite-mask('arrow-right-flat');
      }
    }
  }

  .th-banner-organic {
    margin: 48px 0;
    container-type: inline-size;

    &.-light-blue {
      background: linear-gradient(0deg, #eaf4fe, #eaf4fe),
        linear-gradient(95deg, #0b1450 32.66%, #026a9d 104.92%);
      color: #0f0f0f;
      overflow: hidden;
      border-radius: 4px;
      border: 1px solid rgb(43 123 253 / 20%);
    }

    &.-sm-banner {
      background: linear-gradient(346deg, #040c48 0%, #00589c 75%, #04d189 101%),
        linear-gradient(
          100deg,
          #2c7bfd -30.38%,
          #0d2650 21.96%,
          #1d52a9 160.98%
        );
      border-radius: 4px;
      overflow: hidden;

      span {
        font-size: 12px;
        color: white;
      }

      .th-banner-organic {
        &__blog-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        &__title,
        &__title > span {
          font-size: 24px;
          color: $sm-green;
        }

        &__subtitle {
          color: white;
          font-size: 16px;
          font-style: italic;
          margin-bottom: 4px;
          margin-top: 12px;
        }
      }

      .promo-mobile-content {
        &__list {
          margin: 0;
          list-style: none;
          font-size: 14px;
          color: white;

          li {
            gap: 8px;
            display: flex;
            align-items: center;

            svg {
              color: $sm-green;
            }
          }
        }
      }

      .sm-btn {
        position: relative;
        padding: 14px 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-decoration: none;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.15;
        white-space: nowrap;
        transition-property: background-color, box-shadow;
        transition-duration: 0.25s;
        transition-timing-function: linear;
        max-width: 100%;
        border-radius: var(--border-radius);
        color: $sm-dark;
        background: $sm-green;
        border: 1px solid $sm-green;
        width: 100%;
        margin-top: 12px;

        &.btn-mask-icon::after {
          width: 16px;
          height: 16px;
          background-color: $sm-dark;
        }

        &:hover {
          background: $sm-green-light;
        }
      }

      .banner-picture.-flip-img img {
        transform: scaleX(-1);
        object-position: 88% !important;
      }
    }

    &__blog-wrapper {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;

      .htt-btn {
        letter-spacing: 0.5px;
        text-transform: capitalize;
      }

      &.-gap-lg {
        gap: 24px;

        /*
        @media (min-width: 768px) {
          gap: 32px;
        }*/
      }

      @media (min-width: 768px) {
        flex-wrap: nowrap;
      }
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 8px !important;

      &.-title-md {
        font-size: 22px;
        line-height: 1.3;
        font-weight: 700;
      }

      &.-title-larger {
        font-size: 24px;
        line-height: 1.4;
      }

      &.-title-extra-larger {
        font-size: 28px;
        line-height: 1.4;

        @media (max-width: 360px) {
          font-size: 24px;
        }
      }
    }

    &__subtitle {
      font-size: 14px;

      &.-extra-large {
        font-size: 16px;
        line-height: 1.45;
      }
    }

    &__content {
      padding: 0 16px 24px;
      flex: 1 1 auto;
      align-self: center;

      @media (min-width: 768px) {
        padding: 24px 24px 24px 0;
      }

      &.-middle-banner {
        @media (min-width: 768px) {
          padding: 0 16px;
        }
      }

      &.-left-content {
        order: 2;

        @media (min-width: 768px) {
          order: -1;
          padding: 24px 0 24px 24px;
        }

        &.-pd-lg {
          padding: 0 16px 24px;

          @media (min-width: 768px) {
            padding: 32px 0 32px 16px;
          }
        }
      }

      &-lower {
        border-top: 1px solid #cddaf0;
        text-align: left;
        margin-bottom: 24px;
        margin-top: 16px;
        padding-top: 16px;

        & > p {
          font-size: 16px;
          margin-bottom: 16px;
        }
      }
    }

    &__list {
      list-style-type: none !important;
      padding-left: 0 !important;
      margin: 0 0 16px !important;
    }

    &__list-item {
      font-size: 14px;
      gap: 8px;
      display: flex !important;
      align-items: flex-start;

      svg {
        margin-top: 2.5px;
        min-width: 16px;
        min-height: 16px;
      }
    }

    &.-organic-banner-4 {
      p {
        margin-bottom: 0;
      }

      .-content-wrapper {
        padding: 8px 16px;
        border-left: 2px solid #0f0f0f;

        @media (min-width: 400px) and (max-width: 450px) {
          a {
            display: block;
          }
        }
      }
    }

    .partnership {
      font-size: 12px;
      color: #a4a4a4;
      display: flex;
      align-items: center;
    }

    &.-light-blue {
      background-color: #f4f8ff;
    }

    &.-blue {
      background-color: #2c7bfd;
      color: white;
    }

    .banner-picture {
      width: 100%;

      @media (min-width: 768px) {
        display: block;
        width: auto;
      }

      &.full-height {
        @media (min-width: 768px) {
          height: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
      }
    }

    .banner-middle-picture {
      img {
        position: absolute;
        z-index: 2;
        object-fit: contain;
        width: 165px;
        bottom: 0;
        height: auto;
        right: 0;
        margin-right: -40px;
      }
    }

    .-centered-img {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.-side-image {
        &.dark-bg {
          background: #0b1550;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 20px;
        }

        &.img-cover {
          @media (min-width: 768px) {
            height: 460px;
            position: relative;
            object-fit: cover;
          }

          &.-sm-height {
            height: 332px;
          }
        }

        .apple-img {
          filter: drop-shadow(0px 0px 45px #026a9d);
          margin: 0 auto;
          display: block;
          padding: 20px 0 40px;

          @media (min-width: 768px) {
            margin: 0;
            padding: 0;
          }
        }

        .overflow-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @media (min-width: 768px) {
          min-width: 320px;
        }

        &.-clipped-image {
          clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 95%);

          @media (min-width: 768px) {
            clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 5% 100%);
          }
        }
      }
    }

    .-btn {
      text-transform: uppercase;
      font-size: 16px;
      padding: 14px 32px;
      width: unset;
    }

    .-btn svg {
      fill: currentColor;
      margin-left: 8px;
    }

    .inline-link {
      text-decoration: underline;
      color: $color-htt-blue;
      cursor: pointer;
    }

    .font-weight-500 {
      font-weight: 500 !important;
    }
  }

  .th-banner-sm {
    background: linear-gradient(
      90deg,
      #0b1550 30.91%,
      #0b1550 30.92%,
      #046195 65.34%,
      #056194 65.34%
    );
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    color: #fff;
    padding: 32px 16px;

    .partnership {
      font-size: 14px;
      color: #b4b4b4;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .basic-heading {
      font-size: 25px;
      font-weight: 700;
    }

    .htt-btn {
      max-width: 100%;

      &::after {
        -webkit-mask-size: 30px;
        mask-size: 30px;

        @include sprite-mask('arrow-right-flat');
      }
    }

    &-image {
      max-width: 500px;
    }

    &__numbered {
      margin-bottom: 24px;

      &__item {
        background: #365282;
        font-weight: 400;
        font-size: 15px;
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 90px;
        gap: 8px;
        margin-bottom: 24px;
        position: relative;

        &:last-child {
          margin-bottom: 0;

          &::after {
            content: none;
          }
        }

        &::after {
          content: '';
          display: block;
          width: 4px;
          position: absolute;
          left: 25px;
          height: 27px;
          bottom: -26px;
          background: #365282;
          z-index: -1;
        }

        &__number {
          background: transparent;
          font-size: 16px;
          font-weight: 700;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 36px;
        }

        &.first {
          background: #4581b2;

          .sidebar__numbered__item__number {
            background: #448ff7;
          }

          &::after {
            background: linear-gradient(180deg, #448ff7 0%, #365282 100%);
          }
        }
      }
    }
  }
}

@mixin banner-organic-styles {
  .htt-post & .th-banner-organic,
  .learndash & .th-banner-organic {
    &.-centered-img.-side-image {
      min-width: 250px;

      &.-clipped-image {
        clip-path: none;
      }
    }

    picture {
      width: 100%;
    }

    &__blog-wrapper {
      flex-wrap: wrap;
    }

    &__content {
      padding: 0 16px 24px;

      &.-left-content.-pd-lg {
        padding: 0 16px 24px;
        order: 2;
      }
    }
  }
}

.learndash {
  .th-banner-organic {
    &__blog-wrapper {
      flex-wrap: wrap;

      @media (min-width: 768px) {
        flex-wrap: nowrap;
      }

      .-left-content {
        order: 2;

        @media (min-width: 1200px) {
          order: -1;
        }

        &.-pd-lg {
          padding: 0 16px 24px;

          @media (min-width: 1200px) {
            padding: 32px 0 32px 16px;
          }
        }
      }

      .-centered-img {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.-side-image {
          &.img-cover {
            @media (min-width: 1200px) {
              height: 460px;
              position: relative;
              object-fit: cover;
            }
          }

          .apple-img {
            filter: drop-shadow(0px 0px 45px #026a9d);
            margin: 0 auto;
            display: block;
            padding: 20px 0 40px;

            @media (min-width: 1200px) {
              margin: 0;
              padding: 0;
            }
          }

          .banner-picture {
            width: 100%;

            @media (min-width: 1200px) {
              display: block;
              width: auto;
            }

            &.full-height {
              @media (min-width: 1200px) {
                height: 100%;

                img {
                  height: 100%;
                }
              }
            }
          }

          &.-clipped-image {
            clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 95%);

            @media (min-width: 1200px) {
              clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 5% 100%);
            }
          }
        }
      }
    }
  }
}

.ld-focus:not(.ld-focus-sidebar-collapsed) .ld-focus-content {
  @media (min-width: 768px) {
    @include banner-organic-styles;
  }

  @media (max-width: 991px) {
    @include banner-organic-styles;
  }
}
