.htt-footer {
  font-size: 14px;
  padding: 20px 0 40px;

  @media (min-width: 1024px) {
    padding: 48px 0 40px;
  }

  &.hidelinks {
    padding: 0 0 40px;

    @media (min-width: 1024px) {
      padding: 40px 0 40px;
    }

    @media (max-height: 900px) {
      padding: 40px 0 40px;
    }

    .footer-main {
      @media (min-width: 1024px) {
        justify-content: center;
      }

      &__about {
        @media (min-width: 1024px) {
          max-width: 100%;
        }
      }
    }
  }

  %footer-link {
    text-decoration: none;
    font-weight: 300;

    &:not(:hover) {
      color: $dark-text-color;
    }
  }

  .footer-logo {
    @include sprite(
      'howtotrade-logo',
      (
        text-fill: $dark-text-color,
      )
    );
    //display: inline-block;
    width: 133px;
    height: 24px;
    flex-shrink: 0;
  }

  .footer-main {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 1024px) {
      flex-direction: row;
    }

    &__about {
      margin-top: 16px;
      font-size: 12px;

      @media (min-width: 1024px) {
        margin-top: 0;
        max-width: 40%;
        font-size: 14px;
        text-align: left;
      }
    }

    &__nav {
      @media (min-width: 1024px) {
        padding-left: 15%;
        width: 100%;
        max-width: 70%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .htt-footer-menu {
    font-size: 14px;
    padding-right: 15px;

    a {
      @extend %footer-link;
    }

    &__title {
      display: none;
      font-weight: 500;

      @media (min-width: 1024px) {
        display: inline-block;
      }
    }

    &__button {
      font-weight: 500;
    }

    &__list,
    &__item {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__link {
      display: block;
      padding: 12px 0 0;
      padding-left: 12px;

      @media (min-width: 1024px) {
        padding: 0;
        margin-top: 12px;
        display: inline-block;
      }

      &.new {
        &::after {
          content: 'NEW';
          background: $color-htt-blue;
          padding: 2px 4px;
          border-radius: 4px;
          margin-left: 5px;
          color: #fff;
          font-weight: 600;
          font-size: 10px;
        }
      }
    }
  }

  .footer-line {
    border: 1px solid #454a51;
    border-bottom: 0;
  }

  .footer-terms {
    a {
      @extend %footer-link;

      display: inline-block;
      font-weight: 500;

      &:not(:last-child) {
        &::after {
          content: '';
          margin: 0 12px 2px;
          display: inline-block;
          width: 4px;
          height: 4px;
          background: $dark-text-color;
        }
      }
    }
  }

  .footer-disclaimer {
    font-size: 12px;

    a {
      color: $dark-text-color;
    }
  }

  .footer-social {
    position: relative;
    align-items: center;

    a {
      font-size: 0;
      color: $color-htt-blue;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &:nth-child(1) {
        &:hover {
          color: #4267b2;
        }
      }

      &:nth-child(2) {
        &:hover {
          color: #f56040;
        }
      }

      &:nth-child(3) {
        &:hover {
          color: #ff0000;
        }
      }

      &:nth-child(4) {
        &:hover {
          color: #0a66c2;
        }
      }

      &:nth-child(5) {
        &:hover {
          color: #fe2c55;
        }
      }
    }
  }
}
