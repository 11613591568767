.htt-post-sidebar, // Added extra classes as wrappers here to increase specificity of css, to override element (<h2>/<p>) selectors in _post.scss
.hub-sidebar {
  .sidebar {
    max-width: 525px;
    margin: 0 auto;
    transition: 0.5s;
    position: relative;
    background: linear-gradient(95deg, #0b1450 32.66%, #026a9d 104.92%);
    padding: 24px 16px;
    color: #fff;
    border-radius: 4px;
    overflow: hidden;

    &:not(.position-relative) {
      @media (min-width: 1024px) {
        top: 64px;
        position: sticky;
        width: 100%;
      }
    }

    &.full-width-image {
      padding: 0;
      background: linear-gradient(39deg, #0b1450 63.37%, #026a9d 95.63%);

      .sidebar {
        &__partnership {
          padding: 24px 16px 0;
        }

        &__content {
          padding: 10px 16px 16px;

          /*&::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(
                180deg,
                #0b1550 -0.05%,
                rgba(0, 0, 0, 0) 18.4%
              ),
              50% / cover no-repeat;
            width: 100%;
            height: 237px;
          }*/
        }

        &__heading {
          padding: 0;
          font-size: 22px;
          line-height: 1.2;

          .text-branded {
            color: $color-htt-blue !important;
          }
        }
      }

      .htt-btn {
        margin: 8px auto;

        &--large-text {
          white-space: pre-wrap;
          text-transform: capitalize;
          letter-spacing: 0;
        }
      }

      .full-img {
        display: block;
        margin: 0 auto;
        height: 236px;
        object-fit: cover;
      }
    }

    &--sm-partnership {
      &.full-width-image {
        background: linear-gradient(
            296deg,
            #040c48 16%,
            #00589c 66.07%,
            #04d189 96%
          ),
          linear-gradient(
            177.74deg,
            #2c7bfd -30.38%,
            #0d2650 21.96%,
            #1d52a9 160.98%
          );

        .sm-btn {
          position: relative;
          padding: 14px 20px;
          user-select: none;
          text-decoration: none;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.15;
          white-space: nowrap;
          transition-property: background-color, box-shadow;
          transition-duration: 0.25s;
          transition-timing-function: linear;
          max-width: 100%;
          border-radius: var(--border-radius);
          color: $sm-dark;
          background: $sm-green;
          border: 1px solid $sm-green;
          width: 100%;
          margin-bottom: 12px;
          margin-top: 8px;

          &::after {
            width: 16px;
            height: 16px;
            background-color: $sm-dark;
          }

          &:hover {
            background: $sm-green-light;
          }
        }

        .sidebar {
          &__iconlist {
            li {
              display: flex;
              align-items: center;
              gap: 6px;
              padding-bottom: 12px;
              font-size: 14px;

              svg {
                color: $sm-green;
              }
            }
          }

          &__heading {
            padding: 0;
            margin-top: 8px;
            font-size: 28px;
            line-height: 1.2;
            color: $sm-green;

            & > span {
              display: block;
            }
          }

          &__subtitle {
            font-size: 18px;
            font-style: italic;
            margin-top: 12px;
            display: block;
          }

          &__content {
            & > div > span {
              font-size: 14px;
            }
          }
        }
      }

      .trustpilot-widget {
        position: relative;
        transform: scale(0.8);
      }
    }

    // Colors just for light theme
    &.light {
      background: linear-gradient(0deg, #eaf4fe 0%, #eaf4fe 100%),
        linear-gradient(95deg, #0b1450 32.66%, #026a9d 104.92%);
      color: #0f0f0f;

      .sidebar__partnership {
        color: #a4a4a4;
      }

      .sidebar__numbered__item {
        background: #ddebff;

        &.first {
          background: #fff;

          .sidebar__numbered__item__number {
            color: #fff;
          }

          &::after {
            background: linear-gradient(180deg, #fff 0%, #ddebff 100%);
          }
        }

        &::after {
          background: #ddebff;
        }
      }
    }
    // End light theme colors

    &__partnership {
      font-size: 10px;
    }

    &__content {
      position: relative;
    }

    &__image {
      display: none;

      @media (min-height: 752px) {
        display: block;
      }
    }

    &__heading {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin: 16px 0;

      @media (min-width: 720px) {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }
    }

    &__text {
      font-size: 14px;
      margin-bottom: 12px;
    }

    &__numbered {
      margin-bottom: 24px;

      &__item {
        background: #365282;
        font-weight: 400;
        font-size: 15px;
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 90px;
        gap: 8px;
        margin-bottom: 24px;
        position: relative;

        &:last-child {
          margin-bottom: 0;

          &::after {
            content: none;
          }
        }

        &::after {
          content: '';
          display: block;
          width: 4px;
          position: absolute;
          left: 25px;
          height: 27px;
          bottom: -26px;
          background: #365282;
          z-index: -1;
        }

        &__number {
          background: transparent;
          font-size: 16px;
          font-weight: 700;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 36px;
        }

        &.first {
          background: #4581b2;

          .sidebar__numbered__item__number {
            background: #448ff7;
          }

          &::after {
            background: linear-gradient(180deg, #448ff7 0%, #365282 100%);
          }
        }
      }
    }

    &__socialproof {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 12px;
      padding: 14px;
      margin: 12px 0;
      border-radius: var(--border-radius);
      background: rgb(45 123 253 / 20%);
    }

    &__iconlist {
      &__heading {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      &__item {
        font-size: 14px;
        margin-bottom: 12px;
        display: flex;
        align-items: flex-start;

        &__icon {
          display: flex;
          margin-top: 2px;
        }

        &__badge {
          background-image: url('/static/img/trading-contest/shape-gold.svg');
          background-size: contain;
          background-repeat: no-repeat;
          width: 18px;
          height: 18px;
          margin-right: 6px;
          text-align: center;
          display: flex;
          justify-content: center;
          color: black;
          align-items: center;
          font-weight: 500;
          font-size: 12px;
          margin-top: 2px;

          &.--first {
            background-image: url('/static/img/trading-contest/shape-gold.svg');
          }

          &.--second {
            background-image: url('/static/img/trading-contest/shape-silver.svg');
          }

          &.--third {
            background-image: url('/static/img/trading-contest/shape-copper.svg');
          }
        }
      }
    }

    small {
      font-size: 14px;
    }

    &__logos-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;

      .trustpilot-reviews {
        margin-left: 8px;
      }
    }

    .htt-btn {
      margin-top: 8px;
      max-width: 100%;

      &::after {
        -webkit-mask-size: 30px;
        mask-size: 30px;
        display: none;

        @include sprite-mask('arrow-right-flat');
      }

      &::before {
        content: '';
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        flex-shrink: 0;
        -webkit-mask-size: 24px;
        mask-size: 14px;
        width: 24px;
        height: 24px;
        background-color: #fff;

        @include sprite-mask('locker-solid');
      }

      &.blue {
        &:hover {
          &::before {
            background-color: $color-htt-blue;
          }
        }
      }
    }

    .trustpilot-reviews svg {
      width: 163px;
    }
  }
}

.headroom--pinned + #content .sidebar:not(.position-relative) {
  top: calc(64px + 56px);
}
