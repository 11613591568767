.th-page {
  padding-top: 132px;

  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.3;
  }

  @media screen and (min-width: 720px) {
    h1 {
      font-size: 48px;
    }
  }
}
