.htt-section {
  &-highlight {
    background-color: $color-htt-back-blue;
    padding: 28px 8px;
    margin-bottom: 16px;

    ul {
      margin: 0 18px !important;
      padding-left: 0 !important;
      list-style: disc;
    }

    ol {
      margin: 0 18px !important;
      padding-left: 0 !important;
      list-style: disc;

      li::marker {
        font-weight: 400;
      }
    }

    li {
      padding-left: 6px;
      margin-left: 6px;

      > a {
        font-weight: 300;
        font-size: inherit;
        color: inherit;
        text-decoration: underline;
        text-underline-offset: 1px;
      }

      & + li {
        padding-top: 24px;
      }
    }

    &.-keyp {
      li::marker {
        font-weight: 700;
      }
    }

    .title {
      background-color: #ffffff;
      border: 1px solid rgba(44, 123, 253, 0.25);
      min-height: 61px;
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      img {
        flex: 0 0 60px;
        padding: 0 12px 0 16px;
      }

      span {
        flex: 1 1 auto;
        font-weight: 500;
      }
    }

    @media screen and (min-width: 720px) {
      padding: 32px 16px;
      margin-bottom: 24px;
    }
  }

  &-quote {
    position: relative;
    border-left: 4px solid $color-htt-blue;
    margin-bottom: 16px;

    img {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    p {
      font-style: italic;
      padding: 16px 32px 16px 40px;
    }
  }

  &-points {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .points {
      flex: 1 1 50%;
      padding: 12px;

      > p {
        border-bottom: 3px solid transparent;
        padding-bottom: 8px;
        font-weight: 500;
      }

      ul {
        margin: 24px 0 !important;
        padding-left: 0 !important;
        list-style: disc;
      }

      li {
        margin-bottom: 16px;
        font-size: 16px;
        padding: 0 0 0 32px;
        list-style: none;
        min-height: 32px;
      }

      &.pro {
        background-color: rgba(44, 123, 253, 0.05);
        margin: 0 0 24px;

        > p {
          border-color: #2c7bfd;
        }

        li {
          background: url(/static/icon-svg/ok.svg) no-repeat left top 4px;
        }
      }

      &.cons {
        background-color: rgba(255, 94, 94, 0.08);

        > p {
          border-color: #ff5e5e;
        }

        li {
          background: url(/static/icon-svg/nok.svg) no-repeat left top 4px;
        }
      }
    }

    @media screen and (min-width: 720px) {
      flex-direction: row;

      .points {
        padding: 16px;

        &.pro {
          margin: 0 24px 0 0;
        }
      }
    }
  }

  &-keypoints {
    padding: 32px 0;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    gap: 5%;
    border-bottom: 1px dashed rgba(15, 15, 15, 0.5);

    ul {
      margin: 0 18px !important;
      padding-left: 0 !important;
      list-style: disc;
    }

    ol {
      margin: 0 18px !important;
      padding-left: 0 !important;
      list-style: disc;

      li::marker {
        font-weight: 400;
      }
    }

    li {
      padding-left: 6px;
      margin-left: 6px;
      font-weight: 400;
      font-size: 16px;
      color: inherit;

      & + li {
        padding-top: 24px;
      }
    }

    .title {
      padding-bottom: 12px;
      font-size: 22px;
      font-weight: 800;
    }

    @media screen and (min-width: 720px) {
      padding: 20px 0;
      flex-direction: row;

      .title {
        font-size: 24px;
      }
    }
  }
}
